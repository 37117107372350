import './index.css'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import React, { Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { SentryUtils, CircularProgressOverlay, muiTheme } from '@pbt/pbt-ui-components'
import { store } from '@pbt/pbt-portal-ui/src/store'
import ResizeObserver from 'resize-observer-polyfill'

import './locales/i18n'

import App from './components/App'

// polyfill for dynamic lists in safari
window.ResizeObserver = window.ResizeObserver || ResizeObserver

SentryUtils.initSentry()

const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Suspense fallback={<CircularProgressOverlay open />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)
