import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useSelector } from 'react-redux'
import { getCurrentBusinessId } from '@pbt/pbt-portal-ui/src/store/reducers/auth'

const URL = 'https://datastudio.google.com/embed/reporting/69c99a08-057a-43ee-9a1e-9da2736a61a1/page/Gt5bB?params='

const GoogleDataStudioPage = () => {
  const businessId = useSelector(getCurrentBusinessId)
  const params = {
    'ds517.BusinessID': businessId,
    'ds522.BusinessID': businessId,
    'ds525.BusinessID': businessId,
    'ds526.BusinessID': businessId,
    'ds527.BusinessID': businessId,
    'ds528.BusinessID': businessId,
    'ds529.BusinessID': businessId,
    'ds551.BusinessID': businessId,
    'ds552.BusinessID': businessId,
    'ds553.BusinessID': businessId,
    'ds554.BusinessID': businessId,
    'ds555.BusinessID': businessId,
    'ds556.BusinessID': businessId,
    'ds557.BusinessID': businessId,
  }
  const url = URL + encodeURIComponent(JSON.stringify(params))

  return (
    <IframeResizer
      frameBorder="0"
      log={false}
      src={url}
      style={{ minWidth: '100%', height: '100%' }}
      title="google-data-studio-iframe"
    />
  )
}

export default GoogleDataStudioPage
