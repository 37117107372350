import { VideoItem } from '@pbt/pbt-portal-ui/src/types'
import i18n from '../locales/i18n'

enum SpotLightsGroup {
  SALES_REVENUE = 'SALES_REVENUE',
  PRODUCER_PERFORMANCE = 'PRODUCER_PERFORMANCE',
  OPPORTUNITIES = 'OPPORTUNITIES',
  TRENDS_IN_MEDICINE = 'TRENDS_IN_MEDICINE',
}

const SpotLightsVideoContent: Record<SpotLightsGroup, VideoItem[]> = {
  [SpotLightsGroup.SALES_REVENUE]: [
    {
      value: i18n.t('Content:SPOT_LIGHTS.SALES_REVENUE.SALES_REVENUE_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/Ydx03bU_Sic',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.SALES_REVENUE.SALES_TRANSACTION_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/Z79cW2vokWQ',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.SALES_REVENUE.SALES_ACT_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/MDoaUi_Vf50',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.SALES_REVENUE.SALES_ACT_DEVIATION'),
      videoLink: 'https://www.youtube.com/embed/V5DDkQ4Kvhk',
    },
  ],
  [SpotLightsGroup.PRODUCER_PERFORMANCE]: [
    {
      value: i18n.t('Content:SPOT_LIGHTS.PRODUCER_PERFORMANCE.PRODUCER_REVENUE_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/0B5GEhziCJU',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.PRODUCER_PERFORMANCE.PRODUCER_TRANSACTION_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/3JpvxYRmAFc',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.PRODUCER_PERFORMANCE.PRODUCER_ACT_GROWTH'),
      videoLink: 'https://www.youtube.com/embed/RY3keIw0Ylw',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.PRODUCER_PERFORMANCE.PRODUCER_ACT_DEVIATION'),
      videoLink: 'https://www.youtube.com/embed/BmZRpiZcHo4',
    },
  ],
  [SpotLightsGroup.OPPORTUNITIES]: [
    {
      value: i18n.t('Content:SPOT_LIGHTS.OPPORTUNITIES.NEW_CLIENT_TRANSACTIONS'),
      videoLink: 'https://www.youtube.com/embed/vLVRreTy7mc',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.OPPORTUNITIES.CLIENT_BONDING_RATE'),
      videoLink: 'https://www.youtube.com/embed/Us9v7iX_DNQ',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.OPPORTUNITIES.PATIENT_LAPSING_RATE'),
      videoLink: 'https://www.youtube.com/embed/8ILX-do3mC0',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.OPPORTUNITIES.FORWARD_BOOKING_RATE'),
      videoLink: 'https://www.youtube.com/embed/-9ZYon6bOjk',
    },
  ],
  [SpotLightsGroup.TRENDS_IN_MEDICINE]: [
    {
      value: i18n.t('Content:SPOT_LIGHTS.TRENDS_IN_MEDICINE.PATIENT_VISIT_TRANSACTIONS'),
      videoLink: 'https://www.youtube.com/embed/NjLdtBijXx8',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.TRENDS_IN_MEDICINE.AVERAGE_ANNUAL_PATIENT_VISIT_COUNT'),
      videoLink: 'https://www.youtube.com/embed/xr5r-wj-zZA',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.TRENDS_IN_MEDICINE.WELL_EXAM_APPOINTMENT_TYPE'),
      videoLink: 'https://www.youtube.com/embed/WWtBoRXwAi8',
    },
    {
      value: i18n.t('Content:SPOT_LIGHTS.TRENDS_IN_MEDICINE.PROGRESS_EXAM_APPOINTMENT_TYPE'),
      videoLink: 'https://www.youtube.com/embed/l27KMqBsGdM',
    },
  ],
}

export default SpotLightsVideoContent
