import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import LoginPage from '@pbt/pbt-portal-ui/src/components/common/auth/LoginPage'
import LoginWithActivationCodeForm from '@pbt/pbt-portal-ui/src/components/common/auth/LoginWithActivationCodeForm'
import useIsAuthenticated from '@pbt/pbt-portal-ui/src/utils/useIsAuthenticated'
import useReloadRedirect from '@pbt/pbt-portal-ui/src/utils/useReloadRedirect'

const Activation = () => {
  const location = useLocation()

  const { isAuthenticated } = useIsAuthenticated()
  const reloadRedirect = useReloadRedirect()

  const search = new URLSearchParams(location.search)
  const redirectTo = search.get('redirectTo') || '/'

  useEffect(() => {
    if (isAuthenticated) {
      reloadRedirect(redirectTo)
    }
  }, [isAuthenticated])

  return (
    <LoginPage>
      <LoginWithActivationCodeForm />
    </LoginPage>
  )
}

export default Activation
