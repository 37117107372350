/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useSelector } from 'react-redux'

import { PermissionArea } from '@pbt/pbt-ui-components'
import AnalyticsPage from '@pbt/pbt-portal-ui/src/components/dashboard/analytics/AnalyticsPage'
import { getCurrentBusinessIsOmniChannel } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { useGetAnalyticsFilterParams } from '@pbt/pbt-portal-ui/src/store/hooks/business'
// @ts-ignore
import { getFeatureToggle } from '@pbt/pbt-portal-ui/src/store/reducers/constants'
import FeatureToggle from '@pbt/pbt-portal-ui/src/constants/featureToggle'
import RhapsodyLinks from '../../../constants/rhapsodyLinks'
// @ts-ignore
import MembersPage from '../team-members/MembersPage'
// @ts-ignore
import PracticesPage from '../practices/PracticesPage'
import AnalyticsDashboard from '../analytics-portal/pages/AnalyticsDashboard'
import ReportBuilder from '../analytics-portal/pages/ReportBuilder'
import Benchmarking from '../benchmark/Benchmarking'
import BenchmarkingPlus from '../benchmark/BenchmarkingPlus'
import SpotLightsVideoContent from '../../../constants/spotLightsVideoContent'

export const HOMEPAGE_PATH = '/'
export const BENCHMARK_PATH = '/benchmark'
export const BENCHMARK_PLUS_PATH = '/benchmarkplus'

export const useGetAnalyticsRoutes = () => {
  const {
    businessName: currentBusinessName,
    date,
    range,
  } = useGetAnalyticsFilterParams()
  const isOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isDynamicRhapsodyDomainEnabled = useSelector(getFeatureToggle(FeatureToggle.REPORTING_DYNAMIC_RHAPSODY_DOMAIN))

  const { todayDate, last30DaysDate } = date
  const { todayRange, last30DaysRange, last120DaysRange } = range
  const rhapsodyDomain = isDynamicRhapsodyDomainEnabled
    ? isOmniChannel
      ? RhapsodyLinks.OMNICHANNEL_PIMS
      : RhapsodyLinks.DEFAULT_PIMS
    : ''

  return [
    {
      component: AnalyticsDashboard,
      path: '/dashboard',
    },
    {
      component: MembersPage,
      path: ['/members/:memberId', '/members'],
      permissions: PermissionArea.PERSON,
    },
    {
      component: Benchmarking,
      path: BENCHMARK_PATH,
      permissions: PermissionArea.BENCHMARKING_LIGHT,
    },
    {
      component: BenchmarkingPlus,
      path: BENCHMARK_PLUS_PATH,
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={278}
          educationalContent={SpotLightsVideoContent.SALES_REVENUE}
        />
      ),
      path: '/reports/sales-revenue',
      permissions: PermissionArea.BENCHMARKING_LIGHT,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={280}
          educationalContent={SpotLightsVideoContent.PRODUCER_PERFORMANCE}
        />
      ),
      path: '/reports/producer-performance',
      permissions: PermissionArea.BENCHMARKING_LIGHT,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={282}
          educationalContent={SpotLightsVideoContent.OPPORTUNITIES}
        />
      ),
      path: '/reports/opportunities',
      permissions: PermissionArea.BENCHMARKING_LIGHT,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={285}
          educationalContent={SpotLightsVideoContent.TRENDS_IN_MEDICINE}
        />
      ),
      path: '/reports/trends-in-medicine',
      permissions: PermissionArea.BENCHMARKING_LIGHT,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={356}
          educationalContent={SpotLightsVideoContent.SALES_REVENUE}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/sales-revenue-plus',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={357}
          educationalContent={SpotLightsVideoContent.PRODUCER_PERFORMANCE}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/producer-performance-plus',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={359}
          educationalContent={SpotLightsVideoContent.OPPORTUNITIES}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/opportunities-plus',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={362}
          educationalContent={SpotLightsVideoContent.TRENDS_IN_MEDICINE}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/trends-in-medicine-plus',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={355}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/fido-optimized-employee-health',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={361}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/fido-inventory',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={364}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/fido-fee-reference-and-revenue-breakdown',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: () => (
        <AnalyticsPage
          dashboard={360}
          signedParams={{ businessName: currentBusinessName }}
        />
      ),
      path: '/reports/fido-doctor-opportunities',
      permissions: PermissionArea.BENCHMARKING_PLUS,
    },
    {
      component: PracticesPage,
      path: ['/practices/:practiceId', '/practices'],
      permissions: PermissionArea.BUSINESS,
    },
    {
      path: '/reports/practice-performance',
      component: () => (
        <AnalyticsPage
          dashboard={390}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/daily-reconciliation',
      component: () => (
        <AnalyticsPage
          dashboard={369}
          signedParams={{ dateRange: todayRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/production',
      component: () => (
        <AnalyticsPage
          dashboard={374}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/group-performance',
      component: () => (
        <AnalyticsPage
          dashboard={410}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.GROUP_REPORTS,
    },
    {
      path: '/reports/reminders-compliance',
      component: () => (
        <AnalyticsPage
          dashboard={386}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/client-list',
      component: () => (
        <AnalyticsPage
          dashboard={isDynamicRhapsodyDomainEnabled ? 470 : 408}
          signedParams={{
            newPatientsFilter: last30DaysRange,
            newClientsFilter: last30DaysRange,
            rhapsodyDomain,
          }}
        />
      ),
      permissions: PermissionArea.MARKETING_REPORTS,
    },
    {
      path: '/reports/inventory-usage',
      component: () => (
        <AnalyticsPage
          dashboard={401}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/magic-quadrant',
      component: () => (
        <AnalyticsPage
          dashboard={isDynamicRhapsodyDomainEnabled ? 475 : 398}
          signedParams={{ dateRange: last30DaysRange, rhapsodyDomain }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/time-tracking',
      component: () => (
        <AnalyticsPage
          dashboard={27}
          signedParams={{
            dateFrom: last30DaysDate,
            dateTo: todayDate,
          }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/group-price-lists',
      component: () => <AnalyticsPage dashboard={34} />,
      permissions: PermissionArea.GROUP_REPORTS,
    },
    {
      path: '/reports/rabies-vaccine-history',
      component: () => (
        <AnalyticsPage
          dashboard={389}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/monthly-sales-summary',
      component: () => (
        <AnalyticsPage
          dashboard={376}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/controlled-substance-log',
      component: () => (
        <AnalyticsPage
          dashboard={407}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/medical-insights',
      component: () => (
        <AnalyticsPage
          dashboard={395}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/client-and-patient-status',
      component: () => <AnalyticsPage dashboard={59} />,
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/marketing-data-quality',
      component: () => <AnalyticsPage dashboard={60} />,
      permissions: PermissionArea.MARKETING_REPORTS,
    },
    {
      path: '/reports/forward-bookings',
      component: () => (
        <AnalyticsPage
          dashboard={402}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/schedule-management',
      component: () => (
        <AnalyticsPage
          dashboard={379}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/customer-acquisition-and-lifetime-value',
      component: () => (
        <AnalyticsPage
          dashboard={405}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/wellness-plans',
      component: () => (
        <AnalyticsPage
          dashboard={377}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/referral-business',
      component: () => (
        <AnalyticsPage
          dashboard={387}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/open-soaps-and-invoices',
      component: () => (
        <AnalyticsPage
          dashboard={isDynamicRhapsodyDomainEnabled ? 472 : 392}
          signedParams={{ dateRange: last30DaysRange, rhapsodyDomain }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/report-builder',
      component: () => <ReportBuilder />,
      permissions: PermissionArea.SELF_SERVE_PORTAL,
    },
    {
      path: '/reports/client-and-patient-status-pa',
      component: () => <AnalyticsPage dashboard={213} />,
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/customer-acquisition-and-lifetime-value-pa',
      component: () => (
        <AnalyticsPage
          dashboard={382}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/daily-reconciliation-pa',
      component: () => (
        <AnalyticsPage
          dashboard={370}
          signedParams={{ dateRange: todayRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/forward-bookings-pa',
      component: () => (
        <AnalyticsPage
          dashboard={383}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/group-performance-pa',
      component: () => <AnalyticsPage dashboard={385} />,
      permissions: PermissionArea.GROUP_REPORTS,
    },
    {
      path: '/reports/inventory-usage-pa',
      component: () => (
        <AnalyticsPage
          dashboard={413}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/monthly-sales-summary-pa',
      component: () => (
        <AnalyticsPage
          dashboard={384}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/practice-performance-pa',
      component: () => (
        <AnalyticsPage
          dashboard={388}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/production-pa',
      component: () => (
        <AnalyticsPage
          dashboard={396}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/schedule-management-pa',
      component: () => (
        <AnalyticsPage
          dashboard={400}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/reminders-compliance-pa',
      component: () => (
        <AnalyticsPage
          dashboard={399}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/magic-quadrant-pa',
      component: () => (
        <AnalyticsPage
          dashboard={394}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/daily-reconciliation-new',
      component: () => (
        <AnalyticsPage
          dashboard={368}
          signedParams={{ dateRange: todayRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/bank-reconciliation',
      component: () => (
        <AnalyticsPage
          dashboard={371}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/invoices-and-payments',
      component: () => (
        <AnalyticsPage
          dashboard={372}
          signedParams={{ dateRange: todayRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/accounting',
      component: () => (
        <AnalyticsPage
          dashboard={isDynamicRhapsodyDomainEnabled ? 473 : 249}
          signedParams={{ creationDate: todayDate, rhapsodyDomain }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/new-client-and-patient-activity',
      component: () => (
        <AnalyticsPage
          dashboard={393}
          signedParams={{ dateRange: last30DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/performance-trends',
      component: () => (
        <AnalyticsPage
          dashboard={391}
          signedParams={{ dateRange: last120DaysRange }}
        />
      ),
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/dental-and-heartworm-overview',
      component: () => <AnalyticsPage dashboard={453} />,
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/appointment-mix-benchmark',
      component: () => <AnalyticsPage dashboard={456} />,
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
    {
      path: '/reports/insured-patients-visits',
      component: () => <AnalyticsPage dashboard={457} />,
      permissions: PermissionArea.ANALYTICS_REPORTS,
    },
  ]
}

export const useGetBenchmarkPlusPaths = () => {
  const analyticsRoutes = useGetAnalyticsRoutes()
  const routesWithPermissions = analyticsRoutes
    .filter(a => a.permissions === PermissionArea.BENCHMARKING_PLUS)
    .map(a => a.path)
  return new Set(routesWithPermissions)
}

export const useGetBenchmarkingLightPaths = () => {
  const analyticsRoutes = useGetAnalyticsRoutes()
  const routesWithPermissions = analyticsRoutes
    .filter(a => a.permissions === PermissionArea.BENCHMARKING_LIGHT)
    .map(a => a.path)
  return new Set(routesWithPermissions)
}

