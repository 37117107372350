import { useSelector } from 'react-redux'
import { getCurrentBusiness } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import * as R from 'ramda'
import { getPracticeTypes } from '@pbt/pbt-portal-ui/src/store/reducers/constants'
import { Utils } from '@pbt/pbt-ui-components'

export const usePetabyteAnalyticsOnly = (): boolean => {
  const business = useSelector(getCurrentBusiness)
  const practiceTypes = useSelector(getPracticeTypes)
  const analyticsPracticeId = Utils.findConstantIdByName('Analytics', practiceTypes)

  const isAnalyticsType = R.equals(analyticsPracticeId)
  return R.any(isAnalyticsType, business?.practiceTypeIds || [])
}
