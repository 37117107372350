import { Utils } from '@pbt/pbt-ui-components'

const RHAPSODY_PROTOCOL = 'https'
const RHAPSODY_TLD = 'rhapsody.vet'

const environmentMarker = Utils.isProduction() ? '' : '.stage'
const buildRhapsodyLink = (prefix: string) =>
  `${RHAPSODY_PROTOCOL}://${prefix}${environmentMarker}.${RHAPSODY_TLD}`

export default {
  DEFAULT_PIMS: buildRhapsodyLink('portal'),
  OMNICHANNEL_PIMS: buildRhapsodyLink('pims'),
  REPORTING: buildRhapsodyLink('reporting'),
  ANALYTICS_SUPPORT: 'https://help.rhapsody.vet/en/collections/2552460-rhapsody-analytics',
}
