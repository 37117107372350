import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import ScoreCircle from './ScoreCircle'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'

const useStyles = makeStyles((theme:PuiTheme) => ({
  root: {
    height: 346,
    width: 296,
    padding: theme.spacing(2),
    overflow: 'hidden',
    borderRadius: 2,
    border: theme.constants.tableBorder,
    justifyContent: 'space-between',
    cursor: 'pointer',
    backgroundColor: theme.colors.tableBackground,
  },
  titleContainer: {
    borderBottom: `1px solid ${theme.colors.secondaryText}`,
    paddingBottom: '2px',
    width: 'fit-content',
    alignItems: 'baseline',
  },
  title: {
    '&::first-letter': {
      fontWeight: 'bold',
      fontSize: '2.8rem',
    },
    fontWeight: 500,
    fontSize: '1.8rem',
    lineHeight: 0.8,
  },
  titleDescription: {
    lineHeight: 1,
    marginTop: theme.spacing(1.5),
    fontSize: '1.2rem',
  },
  report: {
    lineHeight: 1,
    marginTop: theme.spacing(2),
  },
  scores: {
    marginTop: theme.spacing(2),
  },
  viewReport: {
    color: theme.colors.markerHighlighted,
    textDecoration: 'underline',
    marginTop: theme.spacing(2),
  },
}), { name: 'SpotCard' })

export type SpotReport = {
  onClick: () => void
  reports: Array<string>
  title: string
  titleDescription: string
  userScore?: number | null
}

const SpotCard = ({
  title,
  titleDescription,
  userScore,
  onClick,
  reports,
} : SpotReport) => {
  const classes = useStyles()
  const { t } = useAnalyticsTranslations('Benchmark')
  return (
    <Grid
      container
      item
      alignContent="flex-start"
      alignItems="center"
      className={classes.root}
      direction="column"
      onClick={onClick}
    >
      <Grid container item className={classes.titleContainer}>
        {title && (
        <Text className={classes.title}>
            {title}
        </Text>
        )}
      </Grid>
      <Grid container item>
        <Text align="center" className={classes.titleDescription}>
          {titleDescription}
        </Text>
      </Grid>
      <Grid container item className={classes.scores} justifyContent="center">
        <ScoreCircle score={userScore} title={t('Benchmark:MY_SCORE')} />
      </Grid>
      {reports && reports.map(el => (
        <Grid container item justifyContent="center" key={el}>
          <Text align="center" className={classes.report} variant="body2">
            {el}
          </Text>
        </Grid>
      ))}
      <Text align="center" className={classes.viewReport} variant="body2">
        {t('Benchmark:VIEW_REPORTS')}
      </Text>
    </Grid>
  )
}

export default SpotCard
