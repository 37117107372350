import React from 'react'
import * as R from 'ramda'
import makeStyles from '@mui/styles/makeStyles'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'
import { Lock } from '@pbt/pbt-ui-components/src/icons'
import { getScoreColor, GRAY_LOCK } from './Score'

const useStyles = makeStyles(() => ({
  container: {
    width: 66,
    height: 66,
    borderRadius: 33,
    justifyContent: 'center',
    boxShadow: '0px 2px 6px rgba(0,0,0,0.1), 1px -2px 6px rgba(0,0,0,0.1)',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    lineHeight: 1,
    wordSpacing: 1000,
  },
  scoreValue: {
    lineHeight: 1,
    fontSize: '3.2rem',
    fontWeight: 700,
  },
}), { name: 'SpotCard' })

export interface ScoreCircleProps {
  score?: number | null
  title: string
}

const ScoreCircle = ({ score, title }: ScoreCircleProps) => {
  const classes = useStyles()
  const color = getScoreColor(score)
  return (
    <Grid
      container
      item
      className={classes.container}
      direction="column"
    >
      <Text align="center" className={classes.title}>
        {title}
      </Text>
      {R.isNil(score)
        ? (<Lock sx={{ color: GRAY_LOCK, fontSize: '3.2rem' }} />)
        : (
          <Text align="center" className={classes.scoreValue} style={{ color }}>
            {score}
          </Text>
        )}
    </Grid>
  )
}

export default ScoreCircle
