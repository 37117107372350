// english namespaces imports
import CommonEnUS from './en-US/Common.json'
import ContentEnUS from './en-US/Content.json'
import ConstantsEnUS from './en-US/Constants.json'
import DashboardEnUS from './en-US/Dashboard.json'
import PageTitlesEnUS from './en-US/PageTitles.json'
import BenchmarkEnUS from './en-US/Benchmark.json'

// spanish namespaces imports
import CommonEsES from './es-ES/Common.json'
import ContentEsES from './es-ES/Content.json'
import ConstantsEsES from './es-ES/Constants.json'
import DashboardEsES from './es-ES/Dashboard.json'
import PageTitlesEsES from './es-ES/PageTitles.json'
import BenchmarkEsES from './es-ES/Benchmark.json'

// spanish (castelian) namespaces imports
import CommonCaES from './ca-ES/Common.json'
import ContentCaES from './ca-ES/Content.json'
import ConstantsCaES from './ca-ES/Constants.json'
import DashboardCaES from './ca-ES/Dashboard.json'
import PageTitlesCaES from './ca-ES/PageTitles.json'
import BenchmarkCaES from './ca-ES/Benchmark.json'

// portuguese namespaces imports
import CommonPtPT from './pt-PT/Common.json'
import ContentPtPT from './pt-PT/Content.json'
import ConstantsPtPT from './pt-PT/Constants.json'
import DashboardPtPT from './pt-PT/Dashboard.json'
import PageTitlesPtPT from './pt-PT/PageTitles.json'
import BenchmarkPtPT from './pt-PT/Benchmark.json'

// portuguese (brazil) namespaces imports
import CommonPtBR from './pt-BR/Common.json'
import ContentPtBR from './pt-BR/Content.json'
import ConstantsPtBR from './pt-BR/Constants.json'
import DashboardPtBR from './pt-BR/Dashboard.json'
import PageTitlesPtBR from './pt-BR/PageTitles.json'
import BenchmarkPtBR from './pt-BR/Benchmark.json'

export const TRANSLATIONS_EN_US = {
  Common: CommonEnUS,
  Content: ContentEnUS,
  Constants: ConstantsEnUS,
  Dashboard: DashboardEnUS,
  PageTitles: PageTitlesEnUS,
  Benchmark: BenchmarkEnUS,
}

export const TRANSLATIONS_ES_ES = {
  Common: CommonEsES,
  Content: ContentEsES,
  Constants: ConstantsEsES,
  Dashboard: DashboardEsES,
  PageTitles: PageTitlesEsES,
  Benchmark: BenchmarkEsES,
}

export const TRANSLATIONS_CA_ES = {
  Common: CommonCaES,
  Content: ContentCaES,
  Constants: ConstantsCaES,
  Dashboard: DashboardCaES,
  PageTitles: PageTitlesCaES,
  Benchmark: BenchmarkCaES,
}

export const TRANSLATIONS_PT_PT = {
  Common: CommonPtPT,
  Content: ContentPtPT,
  Constants: ConstantsPtPT,
  Dashboard: DashboardPtPT,
  PageTitles: PageTitlesPtPT,
  Benchmark: BenchmarkPtPT,
}

export const TRANSLATIONS_PT_BR = {
  Common: CommonPtBR,
  Content: ContentPtBR,
  Constants: ConstantsPtBR,
  Dashboard: DashboardPtBR,
  PageTitles: PageTitlesPtBR,
  Benchmark: BenchmarkPtBR,
}
