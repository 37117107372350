import React from 'react'
import {
  Grid,
  Fab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { Text, PuiTheme, PuiDialog } from '@pbt/pbt-ui-components'
import makeStyles from '@mui/styles/makeStyles'
import SpotScoreChart from '../../common/images/SpotScoreChart.svg'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'

const useStyles = makeStyles((theme: PuiTheme) => ({
  root: {
    height: 556,
    width: 1129,
    maxWidth: 1129,
  },
  rightChartContainer: {
    backgroundColor: theme.colors.patientEditIcon,
  },
  accessScoreBtn: {
    backgroundColor: theme.colors.markerHighlighted,
    minWidth: 168,
    height: 40,
    borderRadius: 129,
    marginTop: theme.spacing(1),
  },
  pointsList: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.25),
    marginBottom: 0,
  },
  pointText: {
    margin: 0,
  },
  pointIcon: {
    minWidth: theme.spacing(3),
  },
  spotChartContainer: {
    left: 160,
    top: 65,
  },
  trendsInMedicine: {
    left: 70,
    top: 60,
  },
  opportunities: {
    left: 31,
    top: 226,
  },
  producerPerformance: {
    left: 206,
    top: 443,
    width: 215,
  },
  salesRevenue: {
    left: 471,
    top: 192,
    width: 140,
  },
  breakSpaces: {
    whiteSpace: 'break-spaces',
  },
  link: {
    textDecoration: 'none',
  },
}), { name: 'SpotScoreDialog' })

interface SpotScoreMakeUpDialogProps {
  accessScoreLink: string
  benchmarkingPlus: boolean
  onClosed: (event?: any) => void
  opened: boolean
}
const SpotScoreMakeUpDialog = (props: SpotScoreMakeUpDialogProps) => {
  const { accessScoreLink, onClosed, opened, benchmarkingPlus } = props
  const classes = useStyles()
  const { t } = useAnalyticsTranslations('Benchmark')

  const filterItems = benchmarkingPlus ?
    [
      t('Benchmark:INVENTORY_MANAGEMENT'),
      t('Benchmark:PATIENT_LIFETIME'),
      t('Benchmark:FORWARD_BOOKING'),
      t('Benchmark:MARKETING_FUNNEL'),
    ] : [
      t('Benchmark:PRACTICE_SIZE'),
      t('Benchmark:PRACTICE_LOCATION'),
      t('Benchmark:YEARS_IN_BUSINESS'),
      t('Benchmark:NUMBER_OF_DOCTORS_IN_STAFF'),
    ]

  const chartItems: Array<{
    class: string
    description: string
    percent: string
    title:string
  }> = [
    {
      percent: '15%',
      title: t('Benchmark:TRENDS_IN_MEDICINE'),
      description: t('Benchmark:TRENDS_IN_MEDICINE_CHART_DESC'),
      class: classes.trendsInMedicine,
    },
    {
      percent: '20%',
      title: t('Benchmark:OPPORTUNITIES'),
      description: t('Benchmark:OPPORTUNITIES_CHART_DESC'),
      class: classes.opportunities,
    },
    {
      percent: '25%',
      title: t('Benchmark:PRODUCER_PERFORMANCE'),
      description: t('Benchmark:PRODUCER_PERFORMANCE_CHART_DESC'),
      class: classes.producerPerformance,
    },
    {
      percent: '40%',
      title: t('Benchmark:SALES_REVENUE'),
      description: t('Benchmark:SALES_REVENUE_CHART_DESC'),
      class: classes.salesRevenue,
    },
  ]

  return (
    <PuiDialog classes={{ paper: classes.root }} open={opened} onClose={onClosed}>
      <Grid container className={classes.root}>
        <Grid container direction="column" justifyContent="space-around" p={3} width={500}>
          <Grid container direction="column">
            <Text className={classes.breakSpaces} mb={1} variant="hero3">{t('Benchmark:SPOT_SCORE_MAKE_UP')}</Text>
            <Text className={classes.breakSpaces} mb={1} variant="body">{t('Benchmark:SPOT_SCORE_MAKE_UP_PAR1')}</Text>
            <Text variant="body">{t('Benchmark:SPOT_SCORE_MAKE_UP_PAR2')}</Text>
          </Grid>
          <Grid container direction="column" mt={2}>
            <Text variant="hero3">
              {benchmarkingPlus ? t('Benchmark:UNLOCK_MORE') : t('Benchmark:CONNECT_PIMS_TO_UNLOCK')}
            </Text>
            <List dense disablePadding>
              {filterItems.map(li => (
                <ListItem disablePadding className={classes.pointsList} key={li}>
                  <ListItemIcon className={classes.pointIcon}>
                    <Text variant="body">
                      {'\u00B7'}
                    </Text>
                  </ListItemIcon>
                  <ListItemText className={classes.pointText}>
                    <Text variant="body">
                      {li}
                    </Text>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Grid item mt={1}>
              <a className={classes.link} href={accessScoreLink} rel="noreferrer" target="_blank">
                <Fab className={classes.accessScoreBtn} color="inherit" variant="extended">
                  {benchmarkingPlus ? t('Benchmark:ACCESS_YOUR_ANALYTICS') : t('Benchmark:ACCESS_YOUR_SCORE')}
                </Fab>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.rightChartContainer} height={556} position="relative" width={629}>
          {chartItems.map(chartItem => (
            <Grid item className={chartItem.class} key={chartItem.title} position="absolute" width={160}>
              <Text strong variant="subheading3">{chartItem.percent} {chartItem.title}</Text>
              <Text variant="body4">{chartItem.description}</Text>
            </Grid>
          ))}
          <Grid item className={classes.spotChartContainer} position="absolute">
            <img alt={t('Benchmark:SPOT_CHART')} src={SpotScoreChart} />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default SpotScoreMakeUpDialog
