import React, { forwardRef } from 'react'
import { Link } from '@mui/material'

import RhapsodyLinks from '../../../constants/rhapsodyLinks'

const LeftMenuReportBuilderButton = forwardRef((props, ref) => (
  <Link
    href={RhapsodyLinks.REPORTING}
    ref={ref}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  />
))

export default LeftMenuReportBuilderButton
