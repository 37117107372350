import React from 'react'
import { useSelector } from 'react-redux'
import { parse, stringify } from 'query-string'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { omit } from 'ramda'
import { PuiAlert, AlertIconType, PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { getRoleIdByName } from '@pbt/pbt-portal-ui/src/store/reducers/roles'
import { ALERT_TYPES } from '@pbt/pbt-portal-ui/src/constants/alertTypes'
import useDialog from '@pbt/pbt-portal-ui/src/utils/useDialog'
import DialogNames from '@pbt/pbt-portal-ui/src/constants/DialogNames'
import RoleNames from '@pbt/pbt-portal-ui/src/constants/roleNames'
import ListSearchFilterPanel from '@pbt/pbt-portal-ui/src/components//common/lists/ListSearchFilterPanel'
import { getBusinessesTotalCount, getBusinessIsLoading } from '@pbt/pbt-portal-ui/src/store/reducers/businesses'

import PracticesTableComponent from './PracticesTableComponent'
import PracticeDetails from './PracticeDetails'

const useStyles = makeStyles(theme => ({
  button: {
    height: 38,
    width: 160,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}), { name: 'PracticesPage' })

const PracticesPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { practiceId } = useParams()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const businessPermissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const businessGroupPermissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS_GROUP))
  const creatorRoleId = useSelector(getRoleIdByName(RoleNames.AnalyticsAdmin))
  const totalCount = useSelector(getBusinessesTotalCount)
  const isLoading = useSelector(getBusinessIsLoading)

  const [openCreatePracticeDialog] = useDialog(DialogNames.CREATE_PRACTICE)
  const [openCreatePracticeGroupDialog] = useDialog(DialogNames.CREATE_PRACTICE_GROUP)

  if (isMobile && typeof practiceId !== 'undefined') {
    return <PracticeDetails creatorRoleId={creatorRoleId} itemId={practiceId} />
  }

  const queryParams = parse(location.search)

  const cleanUpAlert = () => {
    const updatedQueryParams = omit(['alert'], queryParams)
    navigate(`${location.pathname}?${stringify(updatedQueryParams)}`, { replace: true })
  }

  const headerButtons = (
    <Grid container item justifyContent="space-between" px={3} py={2} wrap={isMobile ? 'wrap' : 'nowrap'}>
      <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      <Grid container item columnSpacing={2} justifyContent="flex-end" wrap="nowrap">
        {businessGroupPermissions.create && (
          <Grid item xs={isMobile}>
            <Fab
              className={classes.button}
              color="inherit"
              variant="extended"
              onClick={() => openCreatePracticeGroupDialog({ creatorRoleId })}
            >
              Add group
            </Fab>
          </Grid>
        )}
        {businessPermissions.create && (
          <Grid item xs={isMobile}>
            <Fab
              className={classes.button}
              color="inherit"
              variant="extended"
              onClick={() => openCreatePracticeDialog({ creatorRoleId })}
            >
              Add practice
            </Fab>
          </Grid>
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      {queryParams.alert && (
        <PuiAlert
          iconType={AlertIconType.WARN}
          message={
            `Please review your current practices.
             You can register a new practice by clicking
             "Add practice".`
          }
          okButtonText="Continue"
          open={queryParams.alert === ALERT_TYPES.ADD_PRACTICE}
          onClose={cleanUpAlert}
          onOk={cleanUpAlert}
        />
      )}
      <PracticesTableComponent
        headerButtons={(businessPermissions.create || businessGroupPermissions.create) ? headerButtons : null}
        practiceId={practiceId}
      />
    </Grid>
  )
}

export default PracticesPage
