import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import {
  getCRUDByArea,
  getCurrentBusiness,
  getHasPIMSAccess,
} from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import {
  Accounting as AccountingIcon,
  Calendar as CalendarIcon,
  Coin as CoinIcon,
  Group as GroupIcon,
  Marketing as MarketingIcon,
  Person as PersonIcon,
} from '@pbt/pbt-ui-components/src/icons'
import NoPermissionPage from '@pbt/pbt-portal-ui/src/components/common/NoPermissionPage'
import AnalyticsPage from '@pbt/pbt-portal-ui/src/components/dashboard/analytics/AnalyticsPage'

import { ClassesType, PermissionArea, PuiTheme } from '@pbt/pbt-ui-components'
import { useGetAnalyticsFilterParams } from '@pbt/pbt-portal-ui/src/store/hooks/business'
import RhapsodyFavicon from '../../../common/icons/RhapsodyFavicon'

import GoogleDataStudioPage from '../GoogleDataStudioPage'
import { ReportingRouteConfig } from '../reportingRouteConfig'
import { PetabyteAnalyticsOnlyReportingRouteConfig as PAReportingRouteConfig } from '../petabyteAnalyticsOnlyReportingRouteConfig'
import DashboardSection from '../DashboardSection'
import DashboardLink from '../DashboardLink'
import { usePetabyteAnalyticsOnly } from '../../../utils/usePetabyteAnalyticsOnly'

const useStyles = makeStyles((theme: PuiTheme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
  },
  groupTitle: {
    color: theme.colors.primaryText,
    fontSize: '1.4rem',
    fontWeight: 500,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  rhapsodyfavicon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
}), { name: 'AnalyticsDashboard' })

interface AnalyticsDashboardProps {
  classes: ClassesType<typeof useStyles>
}

const AnalyticsDashboard = ({
  classes: classesProp,
}: AnalyticsDashboardProps) => {
  const classes = useStyles({ classes: classesProp })

  const businessName = useSelector(getCurrentBusiness)?.name || ''
  const hasPimsAccess = useSelector(getHasPIMSAccess)
  const hasMarketingAccess = useSelector(getCRUDByArea(PermissionArea.MARKETING_REPORTS))?.read
  const hasBaseAnalyticsAccess = useSelector(getCRUDByArea(PermissionArea.ANALYTICS_REPORTS))?.read
  const hasGroupsAccess = useSelector(getCRUDByArea(PermissionArea.GROUP_REPORTS))?.read
  const hasAnalyticsAccess = hasBaseAnalyticsAccess || hasGroupsAccess || hasMarketingAccess

  const analyticsOnly = usePetabyteAnalyticsOnly()
  const { range } = useGetAnalyticsFilterParams()

  return (
    <Grid container className={classes.root}>
      {hasAnalyticsAccess && (
        <>
          {hasBaseAnalyticsAccess && (
            <Grid container item direction="column">
              <Typography className={classes.groupTitle}>
                {businessName} highlights
              </Typography>
              <AnalyticsPage
                dashboard={analyticsOnly ? 426 : 427}
                signedParams={{ lockedDateRange: range.last30DaysRange }}
              />
            </Grid>
          )}
          <Grid container item direction="column">
            <Typography className={classes.groupTitle}>
              {businessName} reports
            </Typography>
            <Grid container item spacing={2}>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={CoinIcon}
                  disabled={!hasBaseAnalyticsAccess}
                  hint={`
                    Evaluate revenue and profitability across locations, categories, and specific line items.
                    Discover sales trends for what’s working or not working.
                  `}
                  title="Revenue Drivers"
                >
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.PRACTICE_PERFORMANCE
                    : ReportingRouteConfig.PRACTICE_PERFORMANCE)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.MAGIC_QUADRANT
                    : ReportingRouteConfig.MAGIC_QUADRANT)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.PRODUCTION
                    : ReportingRouteConfig.PRODUCTION)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.INVENTORY_USAGE
                    : ReportingRouteConfig.INVENTORY_USAGE)}
                  />
                </DashboardSection>
              </Grid>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={CalendarIcon}
                  disabled={!hasBaseAnalyticsAccess}
                  hint={`
                    Analyze trends in forward bookings and appointment volumes.
                    Help identify forward booking opportunities to bring back returning clients and patients.
                  `}
                  title="Scheduling"
                >
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.FORWARD_BOOKINGS
                    : ReportingRouteConfig.FORWARD_BOOKINGS)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.SCHEDULE_MANAGEMENT
                    : ReportingRouteConfig.SCHEDULE_MANAGEMENT)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.REMINDERS_COMPLIANCE
                    : ReportingRouteConfig.REMINDERS_COMPLIANCE)}
                  />
                </DashboardSection>
              </Grid>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={PersonIcon}
                  disabled={!hasBaseAnalyticsAccess}
                  hint={`
                    Analyze patient lifetime value by species, age, and history.
                    Get a clear picture of your active client base, using appointment history.
                  `}
                  title="Customer lifecycle"
                >
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.ACQUISITION_AND_LIFETIME_VALUE
                    : ReportingRouteConfig.ACQUISITION_AND_LIFETIME_VALUE)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.CLIENT_AND_PATIENT_STATUS
                    : ReportingRouteConfig.CLIENT_AND_PATIENT_STATUS)}
                  />
                </DashboardSection>
              </Grid>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={GroupIcon}
                  disabled={!hasGroupsAccess}
                  hint={`
                    Quickly compare pricing and performance across all locations.
                    Evaluate under/over performance and compare pricing.
                  `}
                  title="Group benchmarking"
                >
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.GROUP_PERFORMANCE
                    : ReportingRouteConfig.GROUP_PERFORMANCE)}
                  />
                  <DashboardLink {...ReportingRouteConfig.GROUP_PRICE_LISTS} />
                </DashboardSection>
              </Grid>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={AccountingIcon}
                  disabled={!hasBaseAnalyticsAccess}
                  hint={`
                    Review revenues, payments, and changes in your accounts receivable.
                  `}
                  title="Accounting"
                >
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.MONTHLY_SALES_SUMMARY
                    : ReportingRouteConfig.MONTHLY_SALES_SUMMARY)}
                  />
                  <DashboardLink {...(analyticsOnly
                    ? PAReportingRouteConfig.DAILY_RECONCILIATION
                    : ReportingRouteConfig.DAILY_RECONCILIATION)}
                  />
                </DashboardSection>
              </Grid>
              <Grid container item sm={3} xs={12}>
                <DashboardSection
                  Icon={MarketingIcon}
                  disabled={!hasMarketingAccess}
                  hint={`
                    Follow your marketing all the way from the ad to your website to appointment bookings and revenue.
                    Get a clear picture of your marketing ROI.
                  `}
                  title="Marketing"
                >
                  <DashboardLink {...ReportingRouteConfig.MARKETING_DATA_QUALITY} />
                  <DashboardLink {...ReportingRouteConfig.CLIENT_LIST} />
                </DashboardSection>
              </Grid>
              <Grid container item sm={6} xs={12}>
                <DashboardSection
                  Icon={RhapsodyFavicon}
                  classes={{
                    icon: classes.rhapsodyfavicon,
                  }}
                  disabled={!hasPimsAccess || !hasBaseAnalyticsAccess}
                  hint={`
                    These reports will only populate for locations which are using the Rhapsody PIMS.
                  `}
                  title="Rhapsody PIMS user only"
                >
                  <DashboardLink {...ReportingRouteConfig.MEDICAL_INSIGHTS} />
                  <DashboardLink {...ReportingRouteConfig.CONTROLLED_SUBSTANCE_LOG} />
                  <DashboardLink {...ReportingRouteConfig.RABIES_VACCINE_HISTORY} />
                  <DashboardLink {...ReportingRouteConfig.STAFF_TIME_TRACKING} />
                  <DashboardLink {...ReportingRouteConfig.WELLNESS_PLANS} />
                </DashboardSection>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {hasMarketingAccess && !hasAnalyticsAccess && <GoogleDataStudioPage />}
      {!hasMarketingAccess && !hasAnalyticsAccess && <NoPermissionPage />}
    </Grid>
  )
}

AnalyticsDashboard.propTypes = {
  classes: PropTypes.object,
}

export default AnalyticsDashboard
