import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useLocation } from 'react-router-dom'

import Link from './Link'
import PetabyteAnalytics from './images/Petabyte_analytics.png'
import BenchmarkingLite from './images/AAHA_BenchmarkingLite.svg'
import BenchmarkingPlus from './images/AAHA_BenchmarkingPlus.svg'
import { useAnalyticsTranslations } from '../utils/useAnalyticsTranslations'
import {
  useGetBenchmarkPlusPaths,
  useGetBenchmarkingLightPaths,
  BENCHMARK_PATH,
  BENCHMARK_PLUS_PATH,
  HOMEPAGE_PATH,
} from '../dashboard/routes/analyticsRoutes'

const useStyles = makeStyles(() => ({
  image: {
    height: 34,
  },
}), { name: 'PetabyteAnalyticsLogo' })

const PetabyteAnalyticsLogo = () => {
  const classes = useStyles()
  const { t } = useAnalyticsTranslations('Common')
  const { pathname = '' } = useLocation()

  const isLiteBenchmarking = useGetBenchmarkingLightPaths().has(pathname)
  const isPlusBenchmarking = useGetBenchmarkPlusPaths().has(pathname)

  const linkPath = isLiteBenchmarking
    ? BENCHMARK_PATH
    : isPlusBenchmarking
      ? BENCHMARK_PLUS_PATH
      : HOMEPAGE_PATH

  return (
    <Link aria-label={t('Common:RHAPSODY_SYSTEM_HOMEPAGE')} to={linkPath}>
      {
        isLiteBenchmarking
          ? <img alt="BenchmarkingLite" className={classes.image} src={BenchmarkingLite} />
          : isPlusBenchmarking
            ? <img alt="BenchmarkingPlus" className={classes.image} src={BenchmarkingPlus} />
            : <img alt="PetabyteAnalytics" className={classes.image} src={PetabyteAnalytics} />
      }
    </Link>
  )
}

export default PetabyteAnalyticsLogo
