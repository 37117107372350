import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import RhapsodyLinks from '../../../../constants/rhapsodyLinks'

const ReportBuilder = () => (
  <IframeResizer
    frameBorder="0"
    log={false}
    src={RhapsodyLinks.REPORTING}
    style={{
      minWidth: '100%',
      height: '100%',
    }}
    title="report-builder-iframe"
  />
)

export default ReportBuilder
