import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

const Link = (props: RouterLinkProps) => (
  <MuiLink
    component={RouterLink}
    {...props}
  />
)

export default Link
