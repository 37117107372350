import React, { forwardRef } from 'react'
import { Link, LinkProps } from '@mui/material'

import { getCurrentBusinessIsOmniChannel } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { useSelector } from 'react-redux'
import RhapsodyLinks from '../../../constants/rhapsodyLinks'

const LeftMenuRhapsodyPimsButton = forwardRef<HTMLAnchorElement, LinkProps>(
  function LeftMenuRhapsodyPimsButton(props, ref) {
    const isOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)

    return (
      <Link
        href={isOmniChannel ? RhapsodyLinks.OMNICHANNEL_PIMS : RhapsodyLinks.DEFAULT_PIMS}
        ref={ref}
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      />
    )
  },
)

export default LeftMenuRhapsodyPimsButton
