import { PermissionArea } from '@pbt/pbt-ui-components'
import { v4 as uuid } from 'uuid'

export const PetabyteAnalyticsOnlyReportingRouteConfig = {
  CLIENT_AND_PATIENT_STATUS: {
    id: uuid(),
    text: 'Client and patient status',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/client-and-patient-status-pa',
  },
  ACQUISITION_AND_LIFETIME_VALUE: {
    id: uuid(),
    text: 'Customer acquisition and LTV',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/customer-acquisition-and-lifetime-value-pa',
  },
  DAILY_RECONCILIATION: {
    id: uuid(),
    text: 'Daily reconciliation',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/daily-reconciliation-pa',
  },
  FORWARD_BOOKINGS: {
    id: uuid(),
    text: 'Forward bookings',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/forward-bookings-pa',
  },
  GROUP_PERFORMANCE: {
    id: uuid(),
    text: 'Group performance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.GROUP_REPORTS,
    url: '/reports/group-performance-pa',
  },
  INVENTORY_USAGE: {
    id: uuid(),
    text: 'Inventory usage and receiving',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/inventory-usage-pa',
  },
  MAGIC_QUADRANT: {
    id: uuid(),
    text: 'Magic quadrant',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/magic-quadrant-pa',
  },
  MONTHLY_SALES_SUMMARY: {
    id: uuid(),
    text: 'Monthly sales summary',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/monthly-sales-summary-pa',
  },
  PRACTICE_PERFORMANCE: {
    id: uuid(),
    text: 'Practice performance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/practice-performance-pa',
  },
  PRODUCTION: {
    id: uuid(),
    text: 'Production',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/production-pa',
  },
  REMINDERS_COMPLIANCE: {
    id: uuid(),
    text: 'Reminders compliance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/reminders-compliance-pa',
  },
  SCHEDULE_MANAGEMENT: {
    id: uuid(),
    text: 'Schedule management',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/schedule-management-pa',
  },
}
