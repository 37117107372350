import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Link from './Link'
import { useAnalyticsTranslations } from '../utils/useAnalyticsTranslations'
import { HOMEPAGE_PATH } from '../dashboard/routes/analyticsRoutes'

import BenchmarkingPlus from './images/BenchmarkingLogo.png'

const useStyles = makeStyles(() => ({
  image: {
    height: 34,
  },
}), { name: 'BenchmarkingPlusLogo' })

export function BenchmarkingPlusLogo() {
  const classes = useStyles()

  const { t } = useAnalyticsTranslations('Common')
  return (
    <Link aria-label={t('Common:BENCHMARKING_HOMEPAGE')} to={HOMEPAGE_PATH}>
      <img alt="BenchmarkingPlus" className={classes.image} src={BenchmarkingPlus} />
    </Link>
  )
}
