import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { PuiMomentUtils } from '@pbt/pbt-ui-components'
import { SnackbarProvider } from 'notistack'
import { I18nextProvider } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ConditionalAuth0Provider from '@pbt/pbt-portal-ui/src/providers/ConditionalAuth0Provider'
import Auth from '@pbt/pbt-portal-ui/src/components/auth/Auth'
import Registration from '@pbt/pbt-portal-ui/src/components/registration/Registration'
import LogoutComponent from '@pbt/pbt-portal-ui/src/components/auth/LogoutComponent'
import SignInComponent from '@pbt/pbt-portal-ui/src/components/auth/SignInComponent'
import TermsAndConditionsPage from '@pbt/pbt-portal-ui/src/components/terms-and-conditions/TermsAndConditionsPage'
import ScrollToTop from '@pbt/pbt-portal-ui/src/components/ScrollToTop'
import GoogleAnalytics from '@pbt/pbt-portal-ui/src/components/common/GoogleAnalytics'
import LastGateErrorsBoundary from '@pbt/pbt-portal-ui/src/components/error/LastGateErrorsBoundary'
import Favicon from '@pbt/pbt-portal-ui/src/components/Favicon'
import DialogsManager from '@pbt/pbt-portal-ui/src/components/dialogs-manager/DialogsManager'
import NotificationWatcher from '@pbt/pbt-portal-ui/src/components/dashboard/watchers/NotificationWatcher'
import AuthWatcher from '@pbt/pbt-portal-ui/src/components/dashboard/watchers/AuthWatcher'
import ErrorWatcher from '@pbt/pbt-portal-ui/src/components/error/ErrorWatcher'
import WindowVariableInjector from '@pbt/pbt-portal-ui/src/components/WindowVariableInjector'
import i18nPortal from '@pbt/pbt-portal-ui/src/locales/i18n'
import { fetchConstants } from '@pbt/pbt-portal-ui/src/store/actions/constants'
import { getIsAuthenticated } from '@pbt/pbt-portal-ui/src/store/reducers/auth'

import { auth0Enabled } from '@pbt/pbt-portal-ui/src/utils'
import { useChangeLanguage } from './utils/useChangeLanguage'
import AnalyticsPortal from './dashboard/analytics-portal/AnalyticsPortal'
import PageTitleWatcher from './dashboard/watchers/PageTitleWatcher'
import Activation from './auth/Activation'

const useStyles = makeStyles(theme => ({
  page: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}), { name: 'App' })

const App = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const authenticated = useSelector(getIsAuthenticated)
  const [passwordExpirationError, setPasswordExpirationError] = useState(false)

  const { i18nAnalytics } = useChangeLanguage()

  // As we're using selectors based on constants on other pages we should fetch in an upper level
  useEffect(() => {
    if (!authenticated) {
      dispatch(fetchConstants())
    }
  }, [authenticated])

  return (
    <BrowserRouter>
      <ConditionalAuth0Provider>
        <I18nextProvider i18n={i18nAnalytics}>
          <I18nextProvider i18n={i18nPortal}>
            <LastGateErrorsBoundary>
              <Favicon />
              <LocalizationProvider dateAdapter={PuiMomentUtils}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <CssBaseline />
                  <WindowVariableInjector />
                  <ErrorWatcher />
                  <NotificationWatcher />
                  <PageTitleWatcher />
                  <GoogleAnalytics>
                    <ScrollToTop>
                      <DialogsManager />
                      <div className={classes.page}>
                        <Routes>
                          {auth0Enabled ? (
                            <>
                              <Route
                                element={<LogoutComponent />}
                                path="auth/logout"
                              />
                              <Route
                                element={<SignInComponent />}
                                path="auth/signin"
                              />
                              <Route element={<Activation />} path="auth/activation" />
                            </>
                          ) : (
                            <Route element={<Auth />} path="auth/*" />
                          )}
                          <Route element={<Registration />} path="register/*" />
                          <Route element={<TermsAndConditionsPage />} path="terms-and-conditions" />
                          {!passwordExpirationError && (
                            <Route element={<AnalyticsPortal />} path="/*" />
                          )}
                        </Routes>
                      </div>
                      <AuthWatcher
                        setPasswordExpirationError={setPasswordExpirationError}
                      />
                    </ScrollToTop>
                  </GoogleAnalytics>
                </SnackbarProvider>
              </LocalizationProvider>
            </LastGateErrorsBoundary>
          </I18nextProvider>
        </I18nextProvider>
      </ConditionalAuth0Provider>
    </BrowserRouter>
  )
}

export default App
