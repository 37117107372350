/* eslint-disable no-console */
import i18next, { InitOptions } from 'i18next'
import ICU from 'i18next-icu'
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { LanguageCodes, FallbackLanguageCode, SupportedLngCodesList, Config } from '@pbt/pbt-ui-components/src/localization'

import {
  TRANSLATIONS_EN_US,
  TRANSLATIONS_ES_ES,
  TRANSLATIONS_CA_ES,
  TRANSLATIONS_PT_PT,
  TRANSLATIONS_PT_BR,
} from './languages'

const NameSpaces = ['Common', 'Constants', 'Dashboard', 'PageTitles', 'Benchmark']

const langDetectorOptions: DetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'sessionStorage', 'localStorage', 'navigator'],
  // keys or params to lookup language from
  lookupQuerystring: Config.LookupQueryString,
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // languages to not persist (cookie, localStorage)
  excludeCacheFor: ['cimode'],
}

const enabledI18nDebug = localStorage.getItem('enabledI18nDebug')
const i18nextAnalyticsOptions: InitOptions = {
  debug: enabledI18nDebug ? JSON.parse(enabledI18nDebug) : false,
  defaultNS: 'Common',
  fallbackNS: 'Common',
  ns: NameSpaces,
  detection: langDetectorOptions,
  fallbackLng: FallbackLanguageCode,
  supportedLngs: SupportedLngCodesList,
  load: 'currentOnly',
  react: {
    useSuspense: true,
  },
  resources: {
    [LanguageCodes['en-US']]: TRANSLATIONS_EN_US,
    [LanguageCodes['es-ES']]: TRANSLATIONS_ES_ES,
    [LanguageCodes['ca-ES']]: TRANSLATIONS_CA_ES,
    [LanguageCodes['pt-PT']]: TRANSLATIONS_PT_PT,
    [LanguageCodes['pt-BR']]: TRANSLATIONS_PT_BR,
  },
  returnEmptyString: false, // avoid empty string when there's no available translations yet
}

const i18nAnalytics = i18next.createInstance()
i18nAnalytics
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextAnalyticsOptions, err => {
    if (err) {
      throw new Error(`[i18next-analytics]: something went wrong loading: ${err.toString()}`)
    }
  })

export default i18nAnalytics
