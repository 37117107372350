import { Link, LinkProps } from '@mui/material'
import React, { forwardRef } from 'react'

const LeftMenuAAHAButton = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link
    href="https://www.aaha.org/practice-resources/aaha-benchmarking/"
    ref={ref}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  />
))

export default LeftMenuAAHAButton
