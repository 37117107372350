import React from 'react'
import { Grid } from '@mui/material'
import classNames from 'classnames'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { useNavigate } from 'react-router-dom'
import SpotCard from './SpotCard'
import Spot from './Spot'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'

const useStyles = makeStyles((theme:PuiTheme) => ({
  container: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cardsContainer: {
    zIndex: 10,
    marginTop: theme.spacing(4),
  },
  cardMarginRight: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.colors.secondaryText,
  },
  cardsRow: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}), { name: 'SPOTLight' })

export interface ScoreCircleProps {
  score: number | null
  title: string
}

const Benchmarking = () => {
  const classes = useStyles()
  const { t } = useAnalyticsTranslations('Benchmark')
  const navigate = useNavigate()
  return (
    <Grid container className={classes.container} direction="column" mt={2}>
      <Spot avgScore={50} />
      <Grid container className={classNames(classes.container, classes.cardsContainer)} direction="column">
        <Text className={classes.title} variant="hero">
          {t('Benchmark:SPOTLIGHTS')}
        </Text>
        <Text className={classes.title} variant="subheading">
          {t('Benchmark:SPOTLGHTS_TAP')}
        </Text>
        <Grid container className={classes.cardsRow} direction="row">
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:SALES_REVENUE_GROWTH'),
                t('Benchmark:TRANSACTION_GROWTH'),
                t('Benchmark:ACT_GROWTH'),
                t('Benchmark:ACT_DEVIATION')]}
              title={t('Benchmark:SALES_REVENUE')}
              titleDescription={t('Benchmark:SALES_REVENUE_DESCRIPTION')}
              onClick={() => { navigate('/reports/sales-revenue') }}
            />
          </Grid>
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:PRODUCER_SALES_REVENUE_GROWTH'),
                t('Benchmark:PRODUCER_TRANSACTION_GROWTH'),
                t('Benchmark:PRODUCER_ACT_GROWTH'),
                t('Benchmark:PRODUCER_ACT_DEVIATION'),
              ]}
              title={t('Benchmark:PRODUCER_PERFORMANCE')}
              titleDescription={t('Benchmark:PRODUCER_PERFORMANCE_DESCRIPTION')}
              onClick={() => { navigate('/reports/producer-performance') }}
            />
          </Grid>
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:NEW_CLIENT_TRANSACTIONS'),
                t('Benchmark:PATIENT_LAPSING_RATE'),
                t('Benchmark:CLIENT_BONDING_RATE'),
                t('Benchmark:FORWARD_BOOKING_RATE'),
              ]}
              title={t('Benchmark:OPPORTUNITIES')}
              titleDescription={t('Benchmark:OPPORTUNITIES_DESCRIPTION')}
              onClick={() => { navigate('/reports/opportunities') }}
            />
          </Grid>
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:PATIENT_VISIT_TRANSACTIONS'),
                t('Benchmark:AVERAGE_ANNUAL_PATIENT_VISIT_COUNT'),
                t('Benchmark:WELL_EXAM_APPOINTMENT_TYPE'),
                t('Benchmark:PROGRESS_EXAM_APPOINTMENT_TYPE'),
              ]}
              title={t('Benchmark:TRENDS_IN_MEDICINE')}
              titleDescription={t('Benchmark:TRENDS_IN_MEDICINE_DESCRIPTION')}
              onClick={() => { navigate('/reports/trends-in-medicine') }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Benchmarking
