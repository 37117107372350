/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CityTextField,
  CountrySelect,
  PermissionArea,
  PhoneUtils,
  PlusButton,
  PuiTextField,
  RegionUtils,
  StateSelect,
  Text,
  TimezoneSelect,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import ClinicLogo from '@pbt/pbt-portal-ui/src/components/common/logos/ClinicLogo'
import {
  getBusiness,
  getBusinessIsLoading,
} from '@pbt/pbt-portal-ui/src/store/reducers/businesses'
import { updateBusiness } from '@pbt/pbt-portal-ui/src/store/actions/businesses'
import PhoneInput from '@pbt/pbt-portal-ui/src/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '@pbt/pbt-portal-ui/src/components/common/inputs/RequiredFieldsNotice'
import { getCRUDByArea, getCRUDByAreaForBusiness } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import Expander from '@pbt/pbt-portal-ui/src/components/common/lists/Expander'
import { getRhapsodyPayIsLoading } from '@pbt/pbt-portal-ui/src/store/duck/rhapsodyPay'
import { getTimezones, getBusinessStatus, getTimezoneGroups } from '@pbt/pbt-portal-ui/src/store/reducers/constants'
import { fetchDevices } from '@pbt/pbt-portal-ui/src/store/duck/labOrders'
import EnumSelect from '@pbt/pbt-portal-ui/src/components/common/inputs/EnumSelect'
import DetailsBackButton from '@pbt/pbt-portal-ui/src/components/dashboard/clients/DetailsBackButton'
import TimezoneWarningLabel from '@pbt/pbt-portal-ui/src/components/dashboard/alerts/TimezoneWarningLabel'
import useDialog from '@pbt/pbt-portal-ui/src/utils/useDialog'
import DialogNames from '@pbt/pbt-portal-ui/src/constants/DialogNames'

import Migration from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/MigrationSection'
import CountiesEditableList from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/practices/PracticeDetailsSections/CountiesEditableList'
import PracticeStatusLabel from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/practices/PracticeStatusLabel'
import PracticeDetailsChildRow from './PracticeDetailsChildRow'

import { BaseRoutes } from '../../../constants/routes'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  practiceLogo: {
    padding: 0,
    width: 200,
    height: 112,
    boxShadow: '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
  },
  practiceMainDetailsContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(7),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sectionContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  notice: {
    marginTop: theme.spacing(2),
  },
  childRowsContainer: {
    paddingTop: 21,
  },
  plusButtonContainer: {
    marginTop: theme.spacing(2),
  },
  stateContainer: {
    paddingRight: theme.spacing(1.5),
  },
  zipContainer: {
    paddingLeft: theme.spacing(1.5),
  },
  licensesSectionContainer: {
    marginTop: 0,
  },
  requiredFieldsNoticeContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  panelContainer: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  secondaryText: {
    fontSize: '1.6rem',
    color: theme.colors.secondaryText,
  },
  settingLabel: {
    fontWeight: '500',
    fontSize: '1.3rem',
    marginLeft: theme.spacing(6),
  },
  settingValue: {
    fontSize: '1.3rem',
    marginLeft: theme.spacing(4),
  },
  timezoneWarningLabelContainer: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  statusLabel: {
    width: 'fit-content',
    marginLeft: theme.spacing(1),
  },
  migrationSection: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-3),
  },
}), { name: 'PracticeDetails' })

const getCountiesValue = value => {
  if (!value || value?.length === 0) {
    return ['']
  }

  return value
}

const PracticeDetails = ({
  creatorRoleId,
  itemId,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const business = useSelector(getBusiness(itemId)) || {}
  const isPayConfigLoading = useSelector(getRhapsodyPayIsLoading)
  const isBusinessLoading = useSelector(getBusinessIsLoading)
  const permissions = useSelector(getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business))
  const migrationPermissions = useSelector(getCRUDByArea(PermissionArea.IMPORT))
  const statusUpdatePermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))
  const Timezones = useSelector(getTimezones)
  const TimezoneGroups = useSelector(getTimezoneGroups)
  const BusinessStatus = useSelector(getBusinessStatus)

  const isLoading = isPayConfigLoading || isBusinessLoading

  const [openAddChildDialog] = useDialog(DialogNames.ADD_BUSINESS_CHILD)

  const [children, setChildren] = useState([])
  const [isGroup, setIsGroup] = useState(false)
  const [newLogo, setNewLogo] = useState(false)

  const {
    fields: {
      name,
      status,
      phone,
      email,
      website,
      address,
      suite,
      country,
      city,
      state,
      zipcode,
      timezone,
      enableClientsCommunications,
      counties,
    },
    validate,
    reset,
  } = useFields(
    [
      { name: 'name', validators: ['required'], initialValue: business.name },
      { name: 'status', type: 'select', initialValue: business.statusId },
      { name: 'phone', validators: ['required', 'phone'], initialValue: business.phone || '' },
      { name: 'email', validators: ['email'], initialValue: business.email || '' },
      { name: 'website', validators: ['website'], initialValue: business.website },
      { name: 'address', validators: ['required'], initialValue: business.address },
      { name: 'suite', validators: [], initialValue: business.suite || '' },
      { name: 'country', validators: ['required'], initialValue: RegionUtils.getAvailableCountry(business.country) },
      { name: 'city', validators: ['required'], initialValue: business.city },
      { name: 'state', type: 'select', validators: ['required'], initialValue: business.state },
      { name: 'zipcode', validators: ['required', 'zip'], initialValue: business.zipcode },
      { name: 'timezone', type: 'select', validators: ['required'], initialValue: business.timeZone },
      {
        name: 'enableClientsCommunications',
        type: 'toggle',
        initialValue: Boolean(business.clientsCommunicationEnabled),
      },
      { name: 'counties', initialValue: getCountiesValue(business.counties) },
    ],
    false,
  )

  useEffect(() => {
    dispatch(fetchDevices(itemId))
  }, [itemId])

  useEffect(() => {
    reset()
    setChildren(business.children)
    setIsGroup(business.children && business.children.length > 0)
  }, [business])

  useEffect(() => {
    zipcode.setValue('')
    state.setValue('')
  }, [country.value])

  useEffect(() => {
    zipcode.setValue(business.zipcode || '')
    state.setValue(business.state || '')
  }, [])

  const save = () => {
    if (validate()) {
      const newBusiness = {
        ...business,
        children,
        id: business.id,
        name: name.value,
        phone: PhoneUtils.parsePhoneNumber(phone.value),
        email: email.value,
        website: website.value,
        address: address.value,
        suite: suite.value,
        country: country.value,
        city: city.value,
        state: state.value,
        statusId: status.value,
        zipcode: zipcode.value,
        timeZone: timezone.value,
        logo: newLogo || business.logo,
        clientsCommunicationEnabled: enableClientsCommunications.value,
        counties: counties.value || [],
      }

      dispatch(updateBusiness(newBusiness))
    }
  }

  const practiceNameInput = (
    <PuiTextField
      fullWidth
      disabled={!permissions.update}
      field={name}
      inputProps={{ maxLength: 100 }}
      label={isGroup ? 'Group name*' : 'Practice name*'}
      type="text"
    />
  )

  const onDelete = id => {
    setChildren(children.filter(_ => _ !== id))
  }

  let mainInfo
  if (isGroup) {
    mainInfo = (
      <Grid container item direction="column">
        {practiceNameInput}
        <Grid container item className={classes.childRowsContainer}>
          {children.map(childId => (
            <PracticeDetailsChildRow businessId={childId} key={childId} onDelete={() => onDelete(childId)} />
          ))}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.plusButtonContainer}
          direction="row-reverse"
          justifyContent="flex-end"
        >
          <PlusButton
            label="Add practice"
            onClick={() => {
              openAddChildDialog({
                business,
                onSelected: selected => setChildren([...children, ...selected]),
                creatorRoleId,
              })
            }}
          />
        </Grid>
      </Grid>
    )
  } else {
    mainInfo = (
      <Grid container item justifyContent={isMobile ? 'center' : 'flex-start'}>
        <Grid item>
          <ClinicLogo
            allowUpload={permissions.update}
            alt="userpic"
            className={classes.practiceLogo}
            clinic={{ ...business, logo: newLogo || business.logo }}
            onLogoUploaded={setNewLogo}
          />
        </Grid>
        <Grid container item md className={classes.practiceMainDetailsContainer} direction="column">
          {practiceNameInput}
          <Grid container alignItems="center" wrap="nowrap">
            <Text variant="body">Practice status:</Text>
            {statusUpdatePermissions.update
              ? (
                <EnumSelect
                  accent
                  Constant={BusinessStatus}
                  classes={{
                    statusSelect: classes.statusLabel,
                  }}
                  field={status}
                />)
              : (
                <PracticeStatusLabel className={classes.statusLabel} statusId={status.value} variant="big" />
              )
            }

          </Grid>
        </Grid>
      </Grid>
    )
  }

  const onBackButtonClick = () => {
    navigate('/practices')
  }

  return (
    <>
      {isMobile && (
        <DetailsBackButton onClick={onBackButtonClick}>
          Back to practice(s) list
        </DetailsBackButton>
      )}
      <Expander
        isSaving={isLoading}
        onSaveRequested={permissions.update && save}
      >
        <Grid container className={classes.root} direction="column">
          <Grid container item>
            {mainInfo}
            <Grid container item className={classes.panelContainer} direction="column">
              <Text mb={1} variant="h4">General information</Text>
              <Grid container className={classes.sectionContainer} direction="column">
                <Grid container item direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 4}>
                  <Grid item md={3}>
                    <PhoneInput
                      disabled={!permissions.update}
                      field={{ ...phone, label: 'Phone number*' }}
                    />
                  </Grid>
                  <Grid item md={9}>
                    <PuiTextField
                      fullWidth
                      disabled={!permissions.update}
                      field={email}
                      inputProps={{ maxLength: 100 }}
                      label="Email"
                      margin="normal"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PuiTextField
                    fullWidth
                    disabled={!permissions.update}
                    field={website}
                    inputProps={{ maxLength: 100 }}
                    label="Website"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classNames(classes.sectionContainer, classes.sectionContainerDense)}
                direction="column"
              >
                <Grid container item direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 4}>
                  <Grid item md={8}>
                    <PuiTextField
                      fullWidth
                      disabled={!permissions.update}
                      field={address}
                      inputProps={{ maxLength: 100 }}
                      label="Practice address*"
                      margin="normal"
                      type="text"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <PuiTextField
                      fullWidth
                      disabled={!permissions.update}
                      field={suite}
                      inputProps={{ maxLength: 100 }}
                      label="Suite number"
                      margin="normal"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid container item alignItems="flex-end" spacing={isMobile ? 0 : 4}>
                  <Grid item md={4} xs={12}>
                    <CityTextField
                      required
                      country={country.value}
                      disabled={!permissions.update}
                      field={city}
                    />
                  </Grid>
                  <Grid item className={classes.stateContainer} md={3} xs={4}>
                    <CountrySelect
                      required
                      disabled={!permissions.update}
                      field={country}
                    />
                  </Grid>
                  <Grid item className={classes.stateContainer} md={3} xs={4}>
                    <StateSelect
                      required
                      country={country.value}
                      disabled={!permissions.update}
                      field={state}
                    />
                  </Grid>
                  <Grid item className={classes.zipContainer} md={2} xs={4}>
                    <PuiTextField
                      fullWidth
                      InputProps={{
                        inputComponent: ZipInput,
                        inputProps: { country: country.value },
                      }}
                      disabled={!permissions.update}
                      field={zipcode}
                      label="Zip code*"
                      margin="normal"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid container item alignItems="flex-end">
                  <Grid item md={5} xs={11}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="timezone-select">Timezone*</InputLabel>
                      <TimezoneSelect
                        useTimezoneGroupVariant
                        disabled={!permissions.update}
                        timezoneGroups={TimezoneGroups}
                        timezones={Timezones}
                        value={timezone.value}
                        onChange={event => {
                          timezone.setValue(event.target.value)
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.timezoneWarningLabelContainer}>
                    <TimezoneWarningLabel getChangeTimezoneLink={businessId => `${BaseRoutes.PRACTICES}/${businessId}`} />
                  </Grid>
                </Grid>
                <CountiesEditableList
                  classes={{ container: classes.sectionContainer }}
                  disabled={!permissions.update}
                  field={counties}
                  fieldLabel="County"
                />
                <Grid container item>
                  {business.communicationsPhone && (
                    <Grid container item>
                      <Text strong ml={6} variant="body2">
                        Outbound SMS phone number
                      </Text>
                      <Text ml={4} variant="body2">
                        {business.communicationsPhone}
                      </Text>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {migrationPermissions.read && (
                <Grid className={classes.migrationSection}>
                  <Migration isRhapsodyAnalytics business={business} />
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.requiredFieldsNoticeContainer}>
              <RequiredFieldsNotice
                className={classes.notice}
              />
            </Grid>
          </Grid>
        </Grid>
      </Expander>
    </>
  )
}

export default PracticeDetails
