import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  getBusinessesList,
  getBusinessesTotalCount,
  getBusinessIsLoading,
  getMultipleBusinesses,
} from '@pbt/pbt-portal-ui/src/store/reducers/businesses'
import { fetchBusinessesList, fetchMoreItemsForBusinessesList } from '@pbt/pbt-portal-ui/src/store/actions/businesses'
import ExpandableTable from '@pbt/pbt-portal-ui/src/components/common/lists/ExpandableTable'
import PracticesTable from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/practices/PracticesTable'
import {
  getUrlSearchParam,
  getFieldsQueryMergeObject,
  setStringifyFieldsQueryParam,
  getConstantIdStringByNameString,
  addSearch,
} from '@pbt/pbt-portal-ui/src/utils'
import { getBusinessStatus, getPracticeTypes } from '@pbt/pbt-portal-ui/src/store/reducers/constants'
import { Defaults } from '@pbt/pbt-ui-components'
import { getSearchHighlights } from '@pbt/pbt-portal-ui/src/store/reducers/search'

import PracticeDetails from './PracticeDetails'

const PracticesTableComponent = ({
  practiceId,
  headerButtons,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isLoading = useSelector(getBusinessIsLoading)
  const list = useSelector(getBusinessesList)
  const businesses = useSelector(getMultipleBusinesses(list))
  const totalCount = useSelector(getBusinessesTotalCount)
  const BusinessStatus = useSelector(getBusinessStatus)
  const PracticeTypes = useSelector(getPracticeTypes)
  const searchHighlights = useSelector(getSearchHighlights)

  const query = getUrlSearchParam('query', location.search)
  const fieldsQuery = getUrlSearchParam('fieldsQuery', location.search)
  const searchFieldsObject = getFieldsQueryMergeObject(fieldsQuery)

  const columnFilters = useMemo(
    () => {
      const statusFilter = searchFieldsObject?.status
        ? {
          status: {
            value: getConstantIdStringByNameString(searchFieldsObject.status, BusinessStatus),
            humanReadable: searchFieldsObject.status,
          },
        }
        : {}

      const typeFilter = searchFieldsObject?.type
        ? {
          type: {
            value: getConstantIdStringByNameString(searchFieldsObject.type, PracticeTypes),
            humanReadable: searchFieldsObject.type,
          },
        }
        : {}

      return { ...statusFilter, ...typeFilter }
    },
    [fieldsQuery, BusinessStatus],
  )

  useEffect(() => {
    dispatch(fetchBusinessesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, query, fieldsQuery))
  }, [query, fieldsQuery])

  const navigateToPractice = id => {
    navigate(addSearch(location, `/practices/${id}`))
  }

  const onClose = () => {
    navigate('/practices')
  }

  const isItemLoaded = index => Boolean(businesses[index])

  const loadMoreItems = (startIndex, endIndex) => {
    if (startIndex === 0) {
      return
    }
    dispatch(fetchMoreItemsForBusinessesList(startIndex, endIndex, query, fieldsQuery))
  }

  const onApplyFilter = (filter, value) => {
    const queryParams = new URLSearchParams(location.search)
    if (filter && !R.isNil(value?.humanReadable)) {
      const newFieldsQuery = setStringifyFieldsQueryParam(fieldsQuery, filter, value.humanReadable)
      if (newFieldsQuery) {
        queryParams.set('fieldsQuery', decodeURIComponent(newFieldsQuery))
      } else {
        queryParams.delete('fieldsQuery')
      }
    }
    navigate(`${location.pathname}?${queryParams}`)
  }

  const onClearFilters = () => {
    navigate(location.pathname)
  }

  const highlightProps = {
    practiceName: [...searchHighlights, searchFieldsObject?.practiceName || ''],
    internalName: [...searchHighlights, searchFieldsObject?.internalName || ''],
  }

  return (
    <ExpandableTable
      Expander={PracticeDetails}
      hasSelectedFilters={!R.isEmpty(columnFilters)}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={practiceId}
      title="Practices"
      onClose={onClose}
      onSelected={navigateToPractice}
    >
      <PracticesTable
        filters={columnFilters}
        highlightProps={highlightProps}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        practices={businesses}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default PracticesTableComponent
