import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Defaults } from '@pbt/pbt-ui-components'

import MembersTable from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/members//MembersTable'
import ExpandableTable from '@pbt/pbt-portal-ui/src/components/common/lists/ExpandableTable'
import { fetchMembersList, fetchMoreItemsForMembersList } from '@pbt/pbt-portal-ui/src/store/actions/members'
import { getMemberIsLoading, getMembersList, getMembersTotalCount } from '@pbt/pbt-portal-ui/src/store/reducers/members'
import { getMultipleUsers } from '@pbt/pbt-portal-ui/src/store/reducers/users'

import TeamMemberDetails from './TeamMemberDetails'

const MembersTableComponent = ({
  memberId,
  headerButtons,
  onDetailsClose,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const list = useSelector(getMembersList)
  const members = useSelector(getMultipleUsers(list))
  const isLoading = useSelector(getMemberIsLoading)

  const totalCount = useSelector(getMembersTotalCount)

  useEffect(() => {
    dispatch(fetchMembersList({
      from: 0,
      to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      shorten: true,
      includeInactive: true,
      includeInvitationStatus: true,
    }))
  }, [])

  const navigateToMember = id => {
    navigate(`/members/${id}`)
  }

  const isItemLoaded = index => Boolean(members[index])

  const loadMoreItems = (startIndex, endIndex) => {
    if (startIndex !== 0) {
      dispatch(fetchMoreItemsForMembersList({
        from: startIndex,
        to: endIndex,
        shorten: true,
        includeInactive: true,
        includeInvitationStatus: true,
      }))
    }
  }

  return (
    <ExpandableTable
      Expander={TeamMemberDetails}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={memberId}
      title="Team members"
      onClose={onDetailsClose}
      onSelected={navigateToMember}
    >
      <MembersTable
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        members={members}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default MembersTableComponent
