import React, { useState } from 'react'
import LeftMenu from '@pbt/pbt-portal-ui/src/components/dashboard/menu/LeftMenu'

import useAnalyticsMenuConfig from './useAnalyticsMenuConfig'
import AnalyticsHeader from './AnalyticsHeader'

const AnalyticsNavigatorWrapper = () => {
  const [leftPanelOpen, setLeftPanelOpen] = useState(false)
  const menuConfig = useAnalyticsMenuConfig()
  return (
    <>
      <AnalyticsHeader onSandwichClicked={() => setLeftPanelOpen(true)} />
      <LeftMenu
        menuConfig={menuConfig}
        open={leftPanelOpen}
        setOpen={setLeftPanelOpen}
      />
    </>
  )
}

export default AnalyticsNavigatorWrapper
