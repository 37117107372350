export interface ScoreProps {
  color: string
  label: string
  threshold: number
}

export const GRAY_LOCK = '#D0CFCF'
const EXCELLENT = '#2F5901'
export const SCORES: Array<ScoreProps> = [
  { color: '#E02020', threshold: 20, label: 'POOR' },
  { color: '#E06C00', threshold: 40, label: 'FAIR' },
  { color: '#A8CC31', threshold: 60, label: 'GOOD' },
  { color: '#7DAC1E', threshold: 80, label: 'GREAT' },
  { color: EXCELLENT, threshold: 100, label: 'EXCELLENT' },
]

export const getScoreColor = (score?: number | null) => score ? SCORES.find(s => score <= s.threshold)?.color : EXCELLENT
