// @ts-ignore
import i18n from '../locales/i18n'

export const PageMetaMap = {
  '/auth/login': {
    title: i18n.t('PageTitles:ANALYTICS_LOGIN_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_LOGIN_META_DESCRIPTION'),
  },
  '/auth/lock': {
    title: i18n.t('PageTitles:ANALYTICS_LOGIN_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_LOGIN_META_DESCRIPTION'),
  },
  '/auth/password-reset': {
    title: i18n.t('PageTitles:ANALYTICS_PASSWORD_RESET_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_PASSWORD_RESET_META_DESCRIPTION'),
  },
  '/terms-and-conditions': {
    title: i18n.t('PageTitles:ANALYTICS_TERMS_AND_CONDITIONS_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_TERMS_AND_CONDITIONS_META_DESCRIPTION'),
  },
  '/': {
    title: i18n.t('PageTitles:ANALYTICS_DEFAULT_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_DEFAULT_META_DESCRIPTION'),
  },
}

export const AahaPageMetaMap = {
  '/auth/activation': {
    title: i18n.t('PageTitles:AAHA_ACTIVATION_TITLE'),
    description: i18n.t('PageTitles:ANALYTICS_DEFAULT_META_DESCRIPTION'),
  },
}

export const BenchmarkingPageMetaMap = {
  '/auth/activation': {
    title: i18n.t('PageTitles:BENCHMARKING_ACTIVATION_TITLE'),
    description: '', // Specifically requested from product team to leave empty
  },
  '/': {
    title: i18n.t('PageTitles:BENCHMARKING_DEFAULT_TITLE'),
    description: i18n.t(''),
  },
}
