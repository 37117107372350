import React, { Fragment, useCallback, useState } from 'react'
import * as R from 'ramda'
import classNames from 'classnames'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import { Lock } from '@pbt/pbt-ui-components/src/icons'
import { getScoreColor, GRAY_LOCK, ScoreProps, SCORES } from './Score'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'
import SpotScoreMakeUpDialog from './SpotScoreMakeUpDialog'
import LevelUpDialog from './LevelUpDialog'

const SIZE = 405
const GAP = 24
const SCORE_INDICATOR_SIZE = 40
const CENTRAL_CIRCLE_SIZE = SIZE - GAP * 2
const DIVIDER_WIDTH = 8
const MY_SCORE_CIRCLE_SIZE = Math.round((SIZE - GAP) * 0.67)
const MY_SCORE_CIRCLE_SIZE_PLUS = Math.round((SIZE - GAP) * 0.8)

const useStyles = makeStyles((theme:PuiTheme) => ({
  root: {
    userSelect: 'none',
  },
  gauge: {
    height: `${SIZE / 2}px`,
    width: SIZE,
    position: 'relative',
    display: 'block',
    userSelect: 'none',
  },
  circle: {
    width: `${SIZE - GAP}px`,
    height: `${SIZE - GAP}px`,
    top: `${GAP / 2}px`,
    left: `${GAP / 2}px`,
    borderRadius: `${SIZE}px`,
    borderTopWidth: `${(SIZE - GAP) / 2}px`,
    borderTopStyle: 'solid',
    position: 'absolute',
  },
  whiteCentralCircle: {
    zIndex: 2,
    width: `${CENTRAL_CIRCLE_SIZE}px`,
    height: `${CENTRAL_CIRCLE_SIZE}px`,
    borderRadius: `${CENTRAL_CIRCLE_SIZE / 2}px`,
    backgroundColor: theme.colors.contentBackground,
    top: `${GAP}px`,
    left: `${GAP}px`,
    position: 'absolute',
  },
  noBgColor: {
    backgroundColor: 'unset !important',
  },
  divider: {
    zIndex: 3,
    width: `${DIVIDER_WIDTH}px`,
    height: `${SIZE}px`,
    position: 'absolute',
    left: `${SIZE / 2 - DIVIDER_WIDTH / 2}px`,
    backgroundColor: theme.colors.contentBackground,
  },
  dividerTextContainer: {
    marginTop: `${GAP + 3}px`,
    marginLeft: `${DIVIDER_WIDTH}px`,
  },
  dividerText: {
    fontWeight: 500,
    fontSize: '0.8rem',
  },
  bottomHoverInner: {
    width: `${SIZE}px`,
    top: `${SIZE / 2}px`,
    borderTop: `${GAP}px solid ${theme.colors.contentBackground}`,
    position: 'absolute',
    zIndex: 6,
  },
  bottomHover: {
    width: `${SIZE}px`,
    top: `${SIZE / 2}px`,
    borderTop: `${SIZE / 2}px solid ${theme.colors.contentBackground}`,
    position: 'absolute',
    zIndex: 5,
  },
  myScoreContainer: {
    width: `${MY_SCORE_CIRCLE_SIZE}px`,
    height: `${MY_SCORE_CIRCLE_SIZE}px`,
    borderRadius: `${MY_SCORE_CIRCLE_SIZE / 2}px`,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.1), 1px -2px 6px rgba(0,0,0,0.1)',
    top: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE / 2}px`,
    left: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE / 2}px`,
    position: 'absolute',
    zIndex: 4,
    alignItems: 'center',
  },
  myScoreContainerPlus: {
    width: `${MY_SCORE_CIRCLE_SIZE_PLUS}px`,
    height: `${MY_SCORE_CIRCLE_SIZE_PLUS}px`,
    borderRadius: `${MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.1), 1px -2px 6px rgba(0,0,0,0.1)',
    top: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    left: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    position: 'absolute',
    zIndex: 4,
    alignItems: 'center',
  },
  title: {
    color: theme.colors.secondaryText,
  },
  scoreIndicator: {
    width: SCORE_INDICATOR_SIZE,
    height: SCORE_INDICATOR_SIZE,
    zIndex: 6,
    marginTop: `${-GAP - 3}px`,
    position: 'absolute',
    left: `${-SCORE_INDICATOR_SIZE / 2 + DIVIDER_WIDTH / 2}px`,
    justifyContent: 'space-around',
  },
  scorePointer: {
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottomWidth: '12px',
    borderBottomStyle: 'solid',
  },
  bottomContainer: {
    zIndex: 8,
  },
  accessScoreBtn: {
    backgroundColor: theme.colors.markerHighlighted,
    width: 280,
    height: 40,
    borderRadius: 129,
  },
  howReportLabel: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  bottomBorderShadowPlus: {
    width: `${MY_SCORE_CIRCLE_SIZE_PLUS}px`,
    top: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    left: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    position: 'absolute',
    zIndex: 8,
    boxShadow: '0px 2px 0px rgb(0 0 0 / 10%)',
  },
  bottomBorderShadow: {
    width: `${MY_SCORE_CIRCLE_SIZE_PLUS}px`,
    left: `${SIZE / 2 - MY_SCORE_CIRCLE_SIZE_PLUS / 2}px`,
    bottom: 0,
    position: 'absolute',
    zIndex: 8,
  },
}), { name: 'SpotCard' })

export interface SpotProps {
  avgScore: number
  backgroundColor?: string
  benchmarkingPlus?: boolean
  myScore?: number | null
}

const DEMO_LINK = 'https://calendly.com/petabyte/aaha-petabyte-dashboard-demo'
const TEAM_EMAIL_LINK = 'mailto: benchmarking@petabyte.technology '

const Spot = ({ avgScore, myScore, backgroundColor, benchmarkingPlus = false } : SpotProps) => {
  const classes = useStyles()
  const [levelUpDialogOpened, setLevelUpDialogOpened] = useState(false)
  const openLevelUpDialog = useCallback(() => setLevelUpDialogOpened(true), [])
  const closeLevelUpDialog = useCallback(() => setLevelUpDialogOpened(false), [])

  const [spotScoreDialogOpened, setSpotScoreDialogOpened] = useState(false)
  const openSpotScoreDialog = useCallback(() => setSpotScoreDialogOpened(true), [])
  const closeSpotScoreDialog = useCallback(() => setSpotScoreDialogOpened(false), [])

  const { t } = useAnalyticsTranslations('Benchmark')
  const avgScoreColor = getScoreColor(avgScore)
  const scores = SCORES
  const maxScore = scores[scores.length - 1].threshold
  const scoreRotation = avgScore * 180 / maxScore - 90

  const whatsYourScore = (
    <Grid item py={benchmarkingPlus ? 1 : 0} zIndex={10}>
      <Text className={classes.howReportLabel} variant={benchmarkingPlus ? 'body3' : 'body'} onClick={openSpotScoreDialog}>
        {t('Benchmark:WHATS_YOUR_SCORE')}
      </Text>
    </Grid>
  )

  return (
    <Grid container alignItems="center" className={classes.root} direction="column">
      {!benchmarkingPlus && (
      <Text strong className={classes.title} variant="hero">
        {t('Benchmark:MY_SPOT_SCORE')}
      </Text>
      )}
      <Grid
        container
        alignContent="flex-start"
        alignItems="center"
        className={classes.gauge}
        mt={2}
      >
        <div className={classes.gauge}>
          {scores.map((score_: ScoreProps, idx: number, arr: Array<ScoreProps>) => {
            const rotation = (idx > 0 ? arr[idx - 1].threshold : 0) * 180 / maxScore
            const dividerRotation = rotation - 90
            const text = arr[idx].label
            const textRotation = idx === 0 ? 0 : text.length * (400 / SIZE)
            return (
              <Fragment key={score_.label}>
                <div
                  className={classes.circle}
                  style={{ borderColor: score_.color, transform: `rotate(${rotation}deg)` }}
                />
                <div
                  className={classNames(classes.divider, { [classes.noBgColor]: idx === 0 })}
                  style={{ color: score_.color, transform: `rotate(${dividerRotation}deg)` }}
                >
                  <div className={classes.dividerTextContainer}>
                    <Text
                      className={classes.dividerText}
                      style={{ color: score_.color, transform: `rotate(${textRotation}deg)` }}
                    >
                      {text}
                    </Text>
                  </div>
                </div>
              </Fragment>
            )
          })}
          {!R.isNil(avgScore) && (
            <div
              className={classNames(classes.divider, classes.noBgColor)}
              style={{ transform: `rotate(${scoreRotation}deg)` }}
            >
              <Grid container className={classes.scoreIndicator}>
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  justifyContent="end"
                >
                  <Text strong variant="body3">
                    {avgScore}
                  </Text>
                  <div className={classes.scorePointer} style={{ borderBottomColor: `${avgScoreColor}` }} />
                </Grid>
              </Grid>
            </div>
          )}
          <div className={classes.whiteCentralCircle} />
          {backgroundColor &&
            <div className={classes.bottomHoverInner} style={{ borderColor: backgroundColor }} />
          }
          <div className={classes.bottomHover} />
          <Grid
            container
            alignItems="center"
            className={benchmarkingPlus ? classes.bottomBorderShadowPlus : classes.bottomBorderShadow}
            direction="column"
            justifyContent="space-around"
          >
            <Grid item pt={2}>
              <Text variant="body2">{t('Benchmark:MY_SCORE')}</Text>
            </Grid>
            { R.isNil(myScore) ?
              <Lock sx={{ color: GRAY_LOCK, fontSize: '8rem' }} /> :
              <Text strong fontSize="8rem" lineHeight={1} style={{ color: getScoreColor(myScore) }}>{myScore}</Text>
              }
            {benchmarkingPlus && whatsYourScore}
          </Grid>
          <Grid
            container
            className={benchmarkingPlus ? classes.myScoreContainerPlus : classes.myScoreContainer}
            direction="column"
          />
        </div>
      </Grid>
      <Grid container alignItems="center" className={classes.bottomContainer} direction="column">
        <Grid item>
          { benchmarkingPlus ?
            <Text className={classes.howReportLabel} variant="body" onClick={openLevelUpDialog}>
              {t('Benchmark:ACCESS_YOUR_ANALYTICS')}
            </Text> :
            <Fab className={classes.accessScoreBtn} color="inherit" variant="extended" onClick={openLevelUpDialog}>
              {t('Benchmark:ACCESS_YOUR_SCORE')}
            </Fab>
          }
        </Grid>
        {!benchmarkingPlus && whatsYourScore}
        <LevelUpDialog
          benchmarkingPlus={benchmarkingPlus}
          getInTouchLink={benchmarkingPlus ? TEAM_EMAIL_LINK : DEMO_LINK}
          opened={levelUpDialogOpened}
          onClosed={closeLevelUpDialog}
        />
        <SpotScoreMakeUpDialog
          accessScoreLink={DEMO_LINK}
          benchmarkingPlus={benchmarkingPlus}
          opened={spotScoreDialogOpened}
          onClosed={closeSpotScoreDialog}
        />
      </Grid>
    </Grid>
  )
}

export default Spot
