import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'
import { SupportedLngCodesList } from '@pbt/pbt-ui-components/src/localization'
import { updateMomentUiLocale } from '@pbt/pbt-ui-components/src/momentLocale'

import i18nPortalComponents from '@pbt/pbt-portal-ui/src/locales/i18n'
import { getBusiness } from '@pbt/pbt-portal-ui/src/store/reducers/businesses'
import { getCurrentBusinessId } from '@pbt/pbt-portal-ui/src/store/reducers/auth'

import i18nAnalytics from '../../locales/i18n'

export function useChangeLanguage() {
  // There'll be task to refactor this logic later
  const businessId = useSelector(getCurrentBusinessId)
  const business = useSelector(getBusiness(businessId))

  const toggleLang = value => {
    LanguageUtils.updateLanguage({
      languageValue: value,
      i18n: [i18nAnalytics, i18nPortalComponents],
      SupportedLngsValuesList: SupportedLngCodesList,
    })
  }

  useEffect(() => {
    const i18nLanguage = i18nAnalytics.language

    updateMomentUiLocale(i18nLanguage)
  }, [business])

  return {
    i18nAnalytics,
    toggleLang,
  }
}
