import React, { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Fab, Grid, useMediaQuery } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { getCRUDByArea } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import useDialog from '@pbt/pbt-portal-ui/src/utils/useDialog'
import DialogNames from '@pbt/pbt-portal-ui/src/constants/DialogNames'

import { PermissionArea } from '@pbt/pbt-ui-components'
import MembersTableComponent from './MembersTableComponent'
import TeamMemberDetails from './TeamMemberDetails'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  addButton: {
    width: 160,
    margin: theme.spacing(2, 3),
    whiteSpace: 'nowrap',
    height: 38,
  },
}), { name: 'MembersPage' })

const MembersPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { memberId } = useParams()
  const location = useLocation()

  const { openTeamMemberDialog: openTeamMemberDialogProp = false } = location.state || {}
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  const permissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const [openTeamMemberDialog] = useDialog(DialogNames.TEAM_MEMBER)

  const onDetailsClose = () => {
    navigate('/members')
  }

  const tryOpenTeamMemberDialog = () => {
    if (permissions.create) {
      openTeamMemberDialog({
        showTimeTracking: false,
        isRhapsodyAnalytics: true,
        onlyAllowedRoles: true,
      })
    }
  }

  useEffect(() => {
    if (openTeamMemberDialogProp) {
      tryOpenTeamMemberDialog()
    }
  }, [openTeamMemberDialogProp])

  if (isMobile && typeof memberId !== 'undefined') {
    return (
      <TeamMemberDetails itemId={memberId} onClose={onDetailsClose} />
    )
  }

  const headerButtons = (
    <Grid container item justifyContent="flex-end">
      <Fab
        className={classes.addButton}
        color="inherit"
        variant="extended"
        onClick={tryOpenTeamMemberDialog}
      >
        Add team member
      </Fab>
    </Grid>
  )

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <MembersTableComponent
        headerButtons={permissions.create && headerButtons}
        memberId={memberId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default MembersPage
