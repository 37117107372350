import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Hidden, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Defaults,
  ErrorTooltip,
  PermissionArea,
  PhoneUtils,
  PuiTextField,
  useFields,
  UserInvitationStatus,
  Text,
} from '@pbt/pbt-ui-components'

import PhoneInput from '@pbt/pbt-portal-ui/src/components/common/form-inputs/PhoneInput'
import Avatar from '@pbt/pbt-portal-ui/src/components/common/Avatar'
import { DefaultAvatar } from '@pbt/pbt-ui-components/src/icons'
import {
  getCRUDByArea, getCurrentBusinessId,
} from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { getUser } from '@pbt/pbt-portal-ui/src/store/reducers/users'
import {
  inviteMember,
  updateActiveStatus,
  updateMember,
  clearMembersError,
  deleteMemberFromBusinesses,
} from '@pbt/pbt-portal-ui/src/store/actions/members'
import {
  getMemberIsLoading,
  getMembersError,
  getMembersIsActivating,
  getMembersIsDeleting,
  getMembersIsFetchingMember,
  getMembersIsInviting,
  getMembersIsUpdating,
} from '@pbt/pbt-portal-ui/src/store/reducers/members'
import useCloseAfterCreation from '@pbt/pbt-portal-ui/src/utils/useCloseAfterCreation'
import Expander from '@pbt/pbt-portal-ui/src/components/common/lists/Expander'
import DetailsBackButton from '@pbt/pbt-portal-ui/src/components/dashboard/clients/DetailsBackButton'
// eslint-disable-next-line max-len
import MemberRolesSectionContainer from '@pbt/pbt-portal-ui/src/components/dashboard/admin/general/members/MemberDetailsPanelsSections/MemberRolesSectionContainer'
import useDialog from '@pbt/pbt-portal-ui/src/utils/useDialog'
import DialogNames from '@pbt/pbt-portal-ui/src/constants/DialogNames'

const useStyles = makeStyles(theme => ({
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },
  avatar: {
    width: 144,
    height: 144,
  },
  defaultAvatar: {
    width: 144,
    height: 144,
    fontSize: '5rem',
  },
  defaultAvatarBackground: {
    backgroundColor: theme.colors.searchPicker,
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  resendInvitationNotice: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.2rem',
    },
    fontSize: '1.5rem',
    fontWeight: 500,
  },
}), { name: 'TeamMemberDetails' })

const sendInviteButtonLabel = {
  [UserInvitationStatus.PENDING]: 'Resend invitation',
  [UserInvitationStatus.EXPIRED]: 'Resend invitation',
  [UserInvitationStatus.IS_NOT_SENT]: 'Send invitation',
}

const TeamMemberDetails = ({
  itemId,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const teamMemberFromStore = useSelector(getUser(itemId))
  const isUpdating = useSelector(getMembersIsUpdating)
  const isActivating = useSelector(getMembersIsActivating)
  const isDeleting = useSelector(getMembersIsDeleting)
  const isInviting = useSelector(getMembersIsInviting)
  const error = useSelector(getMembersError)
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const isFetchingMember = useSelector(getMembersIsFetchingMember)
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const teamMember = teamMemberFromStore || {}

  const [openUploadImageDialog] = useDialog(DialogNames.UPLOAD_IMAGE)

  const tryDoAction = action => () => {
    if (!error) {
      action()
    }
  }

  const setCloseAfterUpdateOn = useCloseAfterCreation(tryDoAction(onClose), getMemberIsLoading)

  const sendInviteLabel = sendInviteButtonLabel[teamMember.invitationStatus]

  const {
    fields: {
      firstName,
      lastName,
      email,
      mobilePhone,
    },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'firstName',
        validators: ['required'],
        initialValue: teamMember.firstName,
      },
      {
        name: 'lastName',
        validators: ['required'],
        initialValue: teamMember.lastName,
      },
      {
        name: 'email',
        validators: teamMember.email ? ['required', 'email'] : ['email'],
        initialValue: teamMember.email,
      },
      {
        name: 'mobilePhone',
        validators: ['phone'],
        initialValue: teamMember.mobilePhone,
      },
      {
        name: 'znlabsProviderIdentifier',
        initialValue: teamMember.znlabsProviderIdentifier,
      },
    ],
    false,
  )

  const rolesSectionRef = useRef()

  const [newAvatar, setNewAvatar] = useState(false)
  const [resendInvitationPossibility, setResendInvitationPossibility] = useState(true)
  const [sentInvitationsCounter, setSentInvitationsCounter] = useState(0)
  const [resendButtonStatusCounter, setResendButtonStatusCounter] = useState(60)
  const [resendPossibilityTimer, setResendPossibilityTimer] = useState(null)

  const invite = () => {
    setSentInvitationsCounter(sentInvitationsCounter + 1)
    dispatch(inviteMember({
      id: teamMember.id,
      email: email.value,
      businessToRoleList: teamMember.businessToRoleList,
    }))
  }

  const setInviteAfterUpdateOn = useCloseAfterCreation(tryDoAction(invite), getMembersIsUpdating)

  useEffect(() => {
    if (teamMember.firstName) {
      reset()
    }
  }, [teamMemberFromStore])

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const onBackButtonClick = () => navigate('/members')

  const counterDecreasing = () => {
    if (resendButtonStatusCounter !== 0 && resendButtonStatusCounter !== 60) {
      setResendPossibilityTimer(setTimeout(() => {
        setResendButtonStatusCounter(resendButtonStatusCounter - 1)
      }, 1000))
    }
  }

  const checkResendPossibility = () => {
    if (sentInvitationsCounter === Defaults.RESEND_INVITATION_MAX_TIMES) {
      setResendInvitationPossibility(false)

      setResendPossibilityTimer(setTimeout(() => {
        setResendButtonStatusCounter(resendButtonStatusCounter - 1)
      }, 1000))
    }
  }

  const resetResendButtonStatus = () => {
    setResendInvitationPossibility(true)
    setResendButtonStatusCounter(60)
    setSentInvitationsCounter(0)
  }

  const checkResendButtonStatus = () => {
    if (resendButtonStatusCounter === 0) {
      resetResendButtonStatus()
    }
  }

  useEffect(() => {
    checkResendPossibility()

    return () => clearTimeout(resendPossibilityTimer)
  }, [sentInvitationsCounter])

  useEffect(() => {
    counterDecreasing()
    checkResendButtonStatus()
  }, [resendButtonStatusCounter])

  const update = afterUpdate => {
    const isValid = validate()
    const validateRoles = rolesSectionRef.current.validate
    if (isValid && validateRoles()) {
      const newMember = {
        ...teamMember,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        businessToRoleList: rolesSectionRef.current.getBusinessRoleList(),
        avatar: newAvatar || teamMember.avatar,
      }
      afterUpdate()
      dispatch(updateMember(newMember))
    }
  }

  const updateAndClose = () => {
    update(setCloseAfterUpdateOn)
  }

  const updateAndInvite = () => {
    update(setInviteAfterUpdateOn)
  }

  const onAvatarEditRequested = () => {
    openUploadImageDialog({
      onUploaded: setNewAvatar,
    })
  }

  const handleTeamMemberRemove = () => {
    dispatch(deleteMemberFromBusinesses(teamMember.id, [currentBusinessId]))
    onClose()
  }

  const onEmailFocus = () => {
    if (error) {
      dispatch(clearMembersError())
    }
  }

  return (
    <Expander
      isSaving={isUpdating || isFetchingMember}
      onSaveRequested={personPermissions.update && updateAndClose}
    >
      <Grid container item direction="column" flexWrap="nowrap">
        {isMobile && (
          <Grid container item alignItems="center" mb={3}>
            <DetailsBackButton sm={isMobile} onClick={onBackButtonClick}>
              Back to team member(s) list
            </DetailsBackButton>
          </Grid>
        )}
        <Grid container item className={classes.topContainer} wrap="nowrap">
          <Grid item mr={6}>
            <Avatar
              alt="userpic"
              className={classes.avatar}
              classes={{ childrenBackgroundColor: classes.defaultAvatarBackground }}
              person={{ ...teamMember, avatar: newAvatar || teamMember.avatar }}
              size="normal"
              onEditClick={personPermissions.update && onAvatarEditRequested}
            >
              <DefaultAvatar className={classes.defaultAvatar} />
            </Avatar>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <PuiTextField
                disabled={!personPermissions.update}
                field={firstName}
                inputProps={{ maxLength: 100 }}
                label="First name*"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PuiTextField
                disabled={!personPermissions.update}
                field={lastName}
                inputProps={{ maxLength: 100 }}
                label="Last name*"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ErrorTooltip
                message={error}
                open={Boolean(error) && !email.open}
              >
                <PuiTextField
                  autoComplete="email"
                  disabled={!personPermissions.update || Boolean(teamMember.email)}
                  field={email}
                  inputProps={{ maxLength: 100 }}
                  label="Email"
                  type="email"
                  onFocus={onEmailFocus}
                />
              </ErrorTooltip>
            </Grid>
            <Grid item sm={6} xs={12}>
              <PhoneInput disabled={!personPermissions.update} field={{ ...mobilePhone, label: 'Mobile phone number' }} />
            </Grid>
            <Grid container item xs spacing={2}>
              {personPermissions.update && (
                <>
                  <Grid item md={3} sm={6} xs={12}>
                    <ButtonWithLoader
                      className={classes.button}
                      color="secondary"
                      loading={isActivating}
                      onClick={() => {
                        dispatch(updateActiveStatus(
                          teamMember.id,
                          !teamMember.active,
                        ))
                      }}
                    >
                      {teamMember.active ? 'Deactivate' : 'Activate'}
                    </ButtonWithLoader>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <ButtonWithLoader
                      className={classes.button}
                      color="secondary"
                      loading={isDeleting}
                      onClick={handleTeamMemberRemove}
                    >
                      Remove
                    </ButtonWithLoader>
                  </Grid>
                </>
              )}
              <Hidden mdUp smDown>
                <Grid item sm={6} />
              </Hidden>
              {sendInviteLabel && personPermissions.update && (
                <Grid item md={5} sm={6} xs={12}>
                  <ButtonWithLoader
                    className={classes.button}
                    disabled={isInviting || !resendInvitationPossibility}
                    loading={isInviting}
                    onClick={updateAndInvite}
                  >
                    {sendInviteLabel}
                  </ButtonWithLoader>
                </Grid>
              )}
              {!resendInvitationPossibility && (
                <Grid item md={12}>
                  <Typography align="center" className={classes.resendInvitationNotice} color="primary" display="block">
                    {`Several invitations were sent, you will be able to send more invitations after
                  ${resendButtonStatusCounter} seconds.`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Text mb={2} variant="h4">
          Roles
        </Text>
        <MemberRolesSectionContainer
          onlyAlowedRoles
          ref={rolesSectionRef}
          teamMember={teamMember}
        />
      </Grid>
    </Expander>
  )
}

export default TeamMemberDetails
