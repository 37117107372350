import { LanguageUtils } from '@pbt/pbt-ui-components'

export const BaseRoutes = {
  PRACTICES: '/practices',
}

const handleReports = ([name]: string[]) => `Reports: ${LanguageUtils.capitalize(name.trim().replace(/-/g, ' '))}`

export const PathRegexNameMap = {
  '^/reports/(.*)': handleReports,
  '^/practices(.*)': 'Admin: Practices',
  '^/members(.*)': 'Admin: Team Members',
  '^/dashboard(.*)': 'Dashboard',
  '^/report-builder(.*)': 'Report builder',
}
