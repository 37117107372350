import React, { forwardRef } from 'react'
import { Link } from '@mui/material'

import RhapsodyLinks from '../../../constants/rhapsodyLinks'

const LeftMenuSupportButton = forwardRef(function LeftMenuSupportButton(props, ref) {
  return (
    <Link
      href={RhapsodyLinks.ANALYTICS_SUPPORT}
      ref={ref}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    />
  )
})

export default LeftMenuSupportButton
