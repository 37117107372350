import React from 'react'

import { getCurrentEnvironmentString } from '@pbt/pbt-portal-ui/src/utils'

interface RhapsodyFaviconProps {
  className: string
}

const RhapsodyFavicon = ({ className }: RhapsodyFaviconProps) => {
  const env = getCurrentEnvironmentString()
  const src = `${process.env.PUBLIC_URL}/favicons/${env}/favicon.png`

  return (
    <img alt="rhapsody-favicon" className={className} src={src} />
  )
}

export default RhapsodyFavicon
