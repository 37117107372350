import { getCurrentUserId } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { addToRecentList } from '@pbt/pbt-portal-ui/src/utils/recentList'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { isAAHA, isBenchmarking } from '@pbt/pbt-portal-ui/src/utils'
import { AahaPageMetaMap, BenchmarkingPageMetaMap, PageMetaMap } from '../../../constants/pageMeta'
import { PathRegexNameMap } from '../../../constants/routes'

export function getRouteTitleByPath(
  path: string,
  pathRegexNameMap: Record<string, ((matches: string[]) => string) | string>,
) {
  const RegexList = Object.keys(pathRegexNameMap)
  const matchedRegExp = RegexList.find(regExp => RegExp(regExp).test(path))

  if (!matchedRegExp) {
    return undefined
  }

  const handlerOrTitle = pathRegexNameMap[matchedRegExp]
  if (typeof handlerOrTitle !== 'function') {
    return handlerOrTitle
  }
  const matches = path.match(matchedRegExp) || []
  const innerMatches = matches.slice(1)
  return handlerOrTitle(innerMatches)
}

const PageTitleWatcher = () => {
  const location = useLocation()
  const path = location.pathname + location.search

  const currentUserId = useSelector(getCurrentUserId)

  const historyTitle = getRouteTitleByPath(path, PathRegexNameMap)

  useEffect(() => {
    if (historyTitle && currentUserId) {
      addToRecentList(currentUserId, { title: historyTitle, url: path })
    }
  }, [path, historyTitle, currentUserId])

  const isActivationLandingPage = location.pathname === '/auth/activation'
  const isAahaBrandingPage = isAAHA && isActivationLandingPage

  const metaMap = isAahaBrandingPage ? AahaPageMetaMap : isBenchmarking ? BenchmarkingPageMetaMap : PageMetaMap
  const { title, description } = metaMap[location.pathname as keyof typeof metaMap] || metaMap['/' as keyof typeof metaMap]

  return (
    <Helmet>
      <title>{title}</title>
      <meta content={description} name="Description" />
    </Helmet>
  )
}

export default PageTitleWatcher
